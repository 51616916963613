<template>
  <div id="app">
    <div v-if="isLoading" class="loading-animation">
      <div class="bl-w-full bl-p-16 bl-flex bl-flex-col bl-items-center bl-justify-center bl-gap-5">
        <img  src="@/assets/bit.png" alt="Bitlayer" class="bl-w-[155px] bl-h-[155px]"/>
            <i class="el-icon-loading"></i>
          </div>
      
    </div>
    <div v-else>
      <mzh-head v-if="$route.meta.showTabbar"></mzh-head>
      <router-view/>
    </div>
   
  </div>
</template>
<script>
import MzhHead from './components/NavBar.vue'

export default {
  name: 'App',
  components: {
    MzhHead,
  },
  data(){
    return{
      isLoading:true
    };
  },
  mounted() {
    this.startLoadingAnimation();

  },
  methods:{
    startLoadingAnimation() {
      // 模拟加载动作，例如异步获取数据
      setTimeout(() => {
        this.isLoading = false;
      }, 1000); // 假设加载时间是3秒
    }
  
  },
  created() {
    console.log(this.$store.state.address)
  },
}
</script>

<style scoped>
.el-icon-loading{
  font-size: 50px;
}
.loading-animation {
  /* 添加加载动画的CSS样式 */
  animation: fadeIn 2s;
  display: flex;
  align-items: center;
  align-content: center;
}
 
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
.el-collapse-item__header {
  background-color: #e46f1b;
}
body{
background-color:#000;
font-family: var(--bt-locale-font-sans),ui-sans-serif,system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}
</style>
