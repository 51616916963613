export default {
  m: {
    connect: 'Connect Wallet',
    develop: 'Developer',
    st_center: "Eco-Application Center",
    user_center: "User Center",
    develop_1: "Start building in chian",
    word: "Document",
    word_1: "Support Document for Developers",
    lang: "Language",
    jl: "Follow us on the upcoming",
    kt: "Airdrop",
    zg: "Eligibility",
    ts: "Explore",
    knx: "Possibilities Bitcoin",
    bps: "White Paper",
    ll: "The backbone of the Bitcoin ecosystem, a safe and high-speed Layer 2 solution",
    gj: "Start building",
    sy: "Earn income",
    gas: "Median Gas Fee",
    fa: "Solution",
    rh: "What Bitcoin Layer 2's Future Should Be",
    hxfa: "The core solution of the Bitcoin ecosystem",
    yz: "Bitcoin first-layer verification",
    aqx: "Inheriting Bitcoin security through bitvm",
    lg: "Optimistic Rollups",
    md: "Two-way anchoring brings innovative multi-signature models through DLC and BitVM",
    tl: "Complete Turing system",
    vm: "Support VMs and achieve perfect compatibility with EVM environment",
    tu: "Roadmap",
    lc: "The Journey of Bitcoin",
    year: "",
    rsb: "Colored coins",
    tmgl: "Colored coins mark blockchain tokens to represent real-world assets, ensuring security and transparent management.",
    szzc: "Add custom tokens and smart contracts to Bitcoin for building and trading digital assets.",
    jzsj: "Segregated Upgrade",
    kkzx: "Segregated Witness protocol upgrade increases block capacity and improves scalability by separating signature data from transaction data.",
    jh: "Blockchain-based mobile game Spells of Genesis combines trading cards with arcade elements in a fantasy setting.",
    ys: "Rare Pepes digital trading cards featuring frogs are created and exchanged on the Bitcoin blockchain, symbolizing unique collectible crypto art.",
    tsj: "Taproot Upgrade",
    hygn: "Bitcoin protocol upgrade enhances privacy, efficiency, and smart contract capabilities with new Schnorr signature scheme.",
    xygn: "Ordinals protocol engraves unique data on each satoshi of the Bitcoin blockchain, giving NFT-like functionality.",
    blc: "The Journey of BitFAST",
    test: "Test",
    qd: "Initialize the internal network, conduct basic function testing and performance tuning",
    gw: "Public beta",
    fbgt: "Release public test network integrated VMs, invite the community to participate in the test and complete the airdrop",
    zw: "Main network",
    wl: "Release the official version of the network, carry out marketing activities, and attract more users to participate",
    news: "Event & News",
    news1: "BitFAST Ecosystem Event & News",
    zxjz: "Follow our latest progress",
    gzjh: "Job opportunities",
    zwkq: "Job vacancies",
    history: "Let's create Bitcoin history together! ",
    fzr: "Head of Developer Relations",
    kfzgx: "Responsible for expanding and maintaining developer relations in the public chain ecosystem",
    kfzjy: "Handling technical exchanges and developer education with the global developer technology community and ecosystem",
    more: "More information",
    yjy: "Protocol Researcher",
    tdian: "Conduct cutting-edge blockchain research based on industry pain points",
    lw: "Write high-quality research reports and papers",
    tskq: "Explore job vacancies",
    sheq: "Community",
    jrsq: "Join the community",
    hdsq: "Interact with BitFAST",
    jrdw: "Join the BitFAST developer team",
    wmgj: "Build with us",
    jrdskd: "Join Discord",
    cyjl: "Participate in discussions and exchanges",
    gztt: "Follow the official Twitter",
    zxzxjz: "Always get the latest progress",
    lxwm: "Contact us on LinkedIn",
    ywmlx: "Contact us",
    jrdbq: "Join our Telegram group",
    bzzx: "Get help and information",
    gzmdgx: "Follow Medium updates",
    gdzn: "Get more information and guides",
    cy: "Get involved",
    thwj: "Ready Player One",
    gj2: "Build",
    gj3: "Build on BitFAST",
    slt: "Faucet",
    qb: "Supported wallets",
    llq: "BitFAST(BTR) Browser",
    cswllq: "Testnet Browser",
    about: "About",

  }
}
