<template>
  <div class="home">
    <div class="about">
      <div class="home1">
        <div style="width: 100%;display: flex;flex-direction: column;align-items: center;">
          <div class="home1-1">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div class="home1-2">
            <div class="home1-2-1">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                class="bl-w-[16px] bl-mr-[10px] md:bl-w-[30px] bl-hidden md:bl-block">
                <path d="M6 4H18L22 9L12 21L2 9L6 4Z" stroke="#E36E1B" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"></path>
                <path d="M2 9H22" stroke="#E36E1B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                </path>
                <path d="M12 21L8 9" stroke="#E36E1B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                </path>
                <path d="M12 21L16 9" stroke="#E36E1B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                </path>
                <path d="M4 6.5L2 9L12 21L22 9L20 6.5" stroke="#E36E1B" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"></path>

              </svg>
              <span class="home1-txt" style="padding-left: .5rem;">{{ $t('m.jl') }}</span>
              <span class="home1-txt2">{{$t('m.kt')}}</span>
              <span class="home1-txt">{{$t('m.zg')}}</span>
            </div>
            <img class="home1-img" src="https://www.bitlayer.org/images/walkthrough-03.png" />
          </div>
        </div>
        <h3 class="home1-3 white">{{$t('m.ts')}}</h3>
        <h3 class="home1-3 yellow ww">{{$t('m.knx')}}</h3>
        <div style="display: flex;justify-content: center;flex-direction: column;align-items: center;">
          <div class="home1-4 qj2 pdf1" @click="pdf" id="downloadBtn">
            BitFAST {{$t('m.bps')}}
          </div>

          <div class="home1-4 qj2 pdf2">
            <a href="/BITFAST.pdf" download="bitfast.pdf">BitFAST {{$t('m.bps')}}</a>
          </div>

          <div class="white home1-3-txt">{{$t('m.ll')}}</div>

          <div class="home1-5">
            <div class="home1-4 w bg-w qj">
              {{$t('m.gj')}}
            </div>
            <div class="w l20 zoom-animation btn  home1-4  qj">
              {{$t('m.sy')}}
            </div>
          </div>
        </div>
      </div>
      <div class="home2">
        <div class="home2-1">
          <h3 class="home2-1-txt s1">BitFAST</h3>
          <h3 class="home2-2-txt s1">TVL</h3>
        </div>
        <div class="home2-1 num-grid">
          <img class="num" src="@/assets/dollar.png" />
          <img class="num" src="@/assets/0.png" />
          <img class="num" src="@/assets/0.png" />
          <img class="num" src="@/assets/0.png" />
          <img class="num" src="@/assets/,.png" />
          <img class="num" src="@/assets/0.png" />
          <img class="num" src="@/assets/0.png" />
          <img class="num" src="@/assets/0.png" />
          <img class="num" src="@/assets/,.png" />
          <img class="num" src="@/assets/0.png" />
          <img class="num" src="@/assets/0.png" />
          <img class="num" src="@/assets/0.png" />
        </div>
        <div class="yellow right">{{$t('m.gas')}}: $ ---</div>



      </div>
      <div>

        <div id="fa" class="fa yellow">{{$t('m.fa')}}
          <div class="jb"></div>
          <div class="jb2"></div>
        </div>


      </div>
      <div class="white w2">
        {{$t('m.rh')}}
      </div>
      <div class="white w3">{{$t('m.hxfa')}}</div>




    </div>

    <div class="card">
      <el-carousel :autoplay="true" height="500px" indicator-position="outside">
        <el-carousel-item>
          <div class="card-item">
            <img class="card-img" src="https://www.bitlayer.org/images/solution-01.png" />
            <div class="card-r">
              <span class="card-t1">01</span>
              <div class="card-t-c">
                <div class="card-t2">{{$t('m.yz')}}</div>
                <div class="card-t3">{{$t('m.aqx')}}</div>
              </div>
            </div>
          </div>
        </el-carousel-item>

        <el-carousel-item>
          <div class="card-item">
            <img class="card-img" src="https://www.bitlayer.org/images/solution-02.png" />
            <div class="card-r">
              <span class="card-t1">02</span>
              <div class="card-t-c">
                <div class="card-t2">{{$t('m.lg')}}</div>
                <div class="card-t3">{{$t('m.md')}}</div>
              </div>
            </div>
          </div>
        </el-carousel-item>

        <el-carousel-item>
          <div class="card-item">
            <img class="card-img" src="https://www.bitlayer.org/images/solution-03.png" />
            <div class="card-r">
              <span class="card-t1">03</span>
              <div class="card-t-c">
                <div class="card-t2">{{$t('m.tl')}}</div>
                <div class="card-t3">{{$t('m.vm')}}</div>
              </div>
            </div>
          </div>
        </el-carousel-item>


      </el-carousel>
    </div>

    <div class="mobile">
      <el-carousel :autoplay="true" height="500px" direction="vertical">
        <el-carousel-item>
          <div class="card-item">
            <img class="card-img" src="https://www.bitlayer.org/images/solution-01.png" />
            <div class="card-r">
              <span class="card-t1">01</span>
              <div class="card-t-c">
                <div class="card-t2">{{$t('m.yz')}}</div>
                <div class="card-t3">{{$t('m.aqx')}}</div>
              </div>
            </div>
          </div>
        </el-carousel-item>

        <el-carousel-item>
          <div class="card-item">
            <img class="card-img" src="https://www.bitlayer.org/images/solution-02.png" />
            <div class="card-r">
              <span class="card-t1">02</span>
              <div class="card-t-c">
                <div class="card-t2">{{$t('m.lg')}}</div>
                <div class="card-t3">{{$t('m.md')}}</div>
              </div>
            </div>
          </div>
        </el-carousel-item>

        <el-carousel-item>
          <div class="card-item">
            <img class="card-img" src="https://www.bitlayer.org/images/solution-03.png" />
            <div class="card-r">
              <span class="card-t1">03</span>
              <div class="card-t-c">
                <div class="card-t2">{{$t('m.tl')}}</div>
                <div class="card-t3">{{$t('m.vm')}}</div>
              </div>
            </div>
          </div>
        </el-carousel-item>


      </el-carousel>
    </div>

    <div class="lxt" @scroll="handleScroll">
      <div id="tu"
        class="bl-inline-flex bl-items-center bl-justify-center bl-transition-colors bl-relative bl-h-[55px] bl-min-w-[260px] bl-text-[26px]/none bl-bg-primary/20 bl-text-primary">
        <span>{{$t('m.tu')}}</span>
        <div class="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-t bl-top-0 bl-left-0"></div>
        <div class="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-b bl-right-0 bl-bottom-0">
        </div>
      </div>
      <div class="white lxt-title">{{$t('m.lc')}}</div>

      <div id="myElement" class="bl-mt-20 bl-px-6">
        <div class="bl-flex bl-flex-col lg:bl-flex-row bl-pt-10 bl-pb-52">
          <div class="bl-flex bl-flex-col lg:bl-flex-row bl-gap-[6px] lg:bl-gap-[9px]">
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
          </div>
          <div class="bl-w-14 bl-h-[15px] lg:bl-h-14 lg:bl-w-5 bl-relative">
            <div id="time1"
              class="bl-absolute bl-flex lg:bl-flex-col bl-items-center bl-w-24 bl-h-16 bl-top-1/2 -bl-translate-y-1/2 lg:bl-h-28 lg:bl-w-16 lg:bl-top-0 lg:bl-translate-y-0 lg:bl-left-1/2 lg:-bl-translate-x-1/2 bl-group">
              <div
                class="bl-text-foreground bl-text-base/normal bl-absolute -bl-left-12 lg:bl-left-auto lg:-bl-top-11 bl-py-4 bl-duration-200 group-hover:bl-text-[18px]/none group-hover:bl-text-white">
                2012{{ $t('m.year') }}</div>
              <div
                class="bl-w-8 bl-h-0.5 lg:bl-h-14 lg:bl-w-0.5 bl-bg-secondary bl-absolute bl-duration-200 group-hover:bl-w-24 lg:group-hover:bl-w-0.5 lg:group-hover:bl-h-28 group-hover:bl-bg-primary">
              </div>
              <div
                class="bl-absolute bl-p-5 bl-right-1 lg:bl-right-auto lg:bl-bottom-1 bl-duration-200 group-hover:bl-translate-x-10 lg:group-hover:bl-translate-x-0 lg:group-hover:bl-translate-y-14">
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none"
                  class="bl-h-[21px] bl-w-[21px]">
                  <path
                    d="M13.781 13.781A5.25 5.25 0 1 0 8.67 7.317a.558.558 0 0 0 0 .105 5.25 5.25 0 1 0 3.688 6.156c.463.133.942.202 1.424.203ZM9.95 7.626a3.937 3.937 0 1 1 2.513 4.593 5.25 5.25 0 0 0-2.67-4.344.656.656 0 0 0 .15-.25h.007Zm-2.73 8.78a3.938 3.938 0 1 1 0-7.876 3.938 3.938 0 0 1 0 7.876Z"
                    fill="currentColor"></path>
                  <path
                    d="M2.625 7.875a.656.656 0 0 0 .656-.656V5.782a.532.532 0 0 1 .532-.532h1.824l-.197.19a.656.656 0 0 0 .214 1.076.656.656 0 0 0 .718-.144L7.685 5.06a.656.656 0 0 0 0-.932L6.372 2.815a.659.659 0 0 0-.932.932l.197.19H3.813a1.844 1.844 0 0 0-1.844 1.845v1.437a.656.656 0 0 0 .656.656ZM18.375 13.125a.656.656 0 0 0-.656.656v1.437a.532.532 0 0 1-.532.532h-1.824l.197-.19a.659.659 0 1 0-.932-.932l-1.313 1.312a.656.656 0 0 0 0 .932l1.313 1.313a.656.656 0 0 0 .932 0 .658.658 0 0 0 0-.932l-.197-.19h1.824a1.844 1.844 0 0 0 1.844-1.845v-1.437a.657.657 0 0 0-.656-.656ZM7.547 9.844h-1.64a.656.656 0 1 0 0 1.312v2.625a.656.656 0 0 0 0 1.313h1.64a1.64 1.64 0 0 0 1.312-2.625 1.64 1.64 0 0 0-1.312-2.625Zm0 3.937h-.328v-.656h.328a.328.328 0 0 1 0 .656Zm0-1.969h-.328v-.656h.328a.328.328 0 0 1 0 .656Z"
                    fill="currentColor"></path>
                </svg>
              </div>
              <div
                class="bl-text-center bl-relative bl-left-28 lg:bl-left-auto lg:bl-top-40 bl-opacity-0 bl-duration-200 group-hover:bl-opacity-100">
                <h3 class="bl-font-title bl-font-bold bl-text-primary bl-text-sm/none bl-whitespace-nowrap">{{$t('m.rsb')}}</h3>
                <div class="bl-text-foreground bl-text-sm/normal bl-w-[140px] md:bl-w-[240px] bl-mt-3.5">
                  {{$t('m.tmgl')}}</div>
              </div>
            </div>
          </div>
          <div class="bl-flex bl-flex-col lg:bl-flex-row bl-gap-[6px] lg:bl-gap-[9px]">
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
          </div>
          <div class="bl-w-14 bl-h-[15px] lg:bl-h-14 lg:bl-w-5 bl-relative">
            <div
              class="bl-absolute bl-flex lg:bl-flex-col bl-items-center bl-w-24 bl-h-16 bl-top-1/2 -bl-translate-y-1/2 lg:bl-h-28 lg:bl-w-16 lg:bl-top-0 lg:bl-translate-y-0 lg:bl-left-1/2 lg:-bl-translate-x-1/2">
              <div
                class="bl-w-8 bl-h-0.5 lg:bl-h-14 lg:bl-w-0.5 bl-bg-secondary bl-absolute bl-duration-200 group-hover:bl-w-24 lg:group-hover:bl-w-0.5 lg:group-hover:bl-h-28 group-hover:bl-bg-primary">
              </div>
              <div
                class="bl-absolute bl-p-5 bl-right-1 lg:bl-right-auto lg:bl-bottom-1 bl-duration-200 group-hover:bl-translate-x-10 lg:group-hover:bl-translate-x-0 lg:group-hover:bl-translate-y-14">
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none"
                  class="bl-h-[21px] bl-w-[21px]">
                  <path
                    d="M13.781 13.781A5.25 5.25 0 1 0 8.67 7.317a.558.558 0 0 0 0 .105 5.25 5.25 0 1 0 3.688 6.156c.463.133.942.202 1.424.203ZM9.95 7.626a3.937 3.937 0 1 1 2.513 4.593 5.25 5.25 0 0 0-2.67-4.344.656.656 0 0 0 .15-.25h.007Zm-2.73 8.78a3.938 3.938 0 1 1 0-7.876 3.938 3.938 0 0 1 0 7.876Z"
                    fill="currentColor"></path>
                  <path
                    d="M2.625 7.875a.656.656 0 0 0 .656-.656V5.782a.532.532 0 0 1 .532-.532h1.824l-.197.19a.656.656 0 0 0 .214 1.076.656.656 0 0 0 .718-.144L7.685 5.06a.656.656 0 0 0 0-.932L6.372 2.815a.659.659 0 0 0-.932.932l.197.19H3.813a1.844 1.844 0 0 0-1.844 1.845v1.437a.656.656 0 0 0 .656.656ZM18.375 13.125a.656.656 0 0 0-.656.656v1.437a.532.532 0 0 1-.532.532h-1.824l.197-.19a.659.659 0 1 0-.932-.932l-1.313 1.312a.656.656 0 0 0 0 .932l1.313 1.313a.656.656 0 0 0 .932 0 .658.658 0 0 0 0-.932l-.197-.19h1.824a1.844 1.844 0 0 0 1.844-1.845v-1.437a.657.657 0 0 0-.656-.656ZM7.547 9.844h-1.64a.656.656 0 1 0 0 1.312v2.625a.656.656 0 0 0 0 1.313h1.64a1.64 1.64 0 0 0 1.312-2.625 1.64 1.64 0 0 0-1.312-2.625Zm0 3.937h-.328v-.656h.328a.328.328 0 0 1 0 .656Zm0-1.969h-.328v-.656h.328a.328.328 0 0 1 0 .656Z"
                    fill="currentColor"></path>
                </svg>
              </div>
            </div>
          </div>
          <div class="bl-flex bl-flex-col lg:bl-flex-row bl-gap-[6px] lg:bl-gap-[9px]">
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
          </div>
          <div class="bl-w-14 bl-h-[15px] lg:bl-h-14 lg:bl-w-5 bl-relative">
            <div id="time2"
              class="bl-absolute bl-flex lg:bl-flex-col bl-items-center bl-w-24 bl-h-16 bl-top-1/2 -bl-translate-y-1/2 lg:bl-h-28 lg:bl-w-16 lg:bl-top-0 lg:bl-translate-y-0 lg:bl-left-1/2 lg:-bl-translate-x-1/2 bl-group">
              <div
                class="bl-text-foreground bl-text-base/normal bl-absolute -bl-left-12 lg:bl-left-auto lg:-bl-top-11 bl-py-4 bl-duration-200 group-hover:bl-text-[18px]/none group-hover:bl-text-white">
                2014{{ $t('m.year') }}</div>
              <div
                class="bl-w-8 bl-h-0.5 lg:bl-h-14 lg:bl-w-0.5 bl-bg-secondary bl-absolute bl-duration-200 group-hover:bl-w-24 lg:group-hover:bl-w-0.5 lg:group-hover:bl-h-28 group-hover:bl-bg-primary">
              </div>
              <div
                class="bl-absolute bl-p-5 bl-right-1 lg:bl-right-auto lg:bl-bottom-1 bl-duration-200 group-hover:bl-translate-x-10 lg:group-hover:bl-translate-x-0 lg:group-hover:bl-translate-y-14">
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none"
                  class="bl-h-[21px] bl-w-[21px]">
                  <path
                    d="M11.156 7.219H8.531a.656.656 0 1 0 0 1.312h.656v3.938h-.656a.656.656 0 1 0 0 1.312h2.625a1.969 1.969 0 0 0 1.969-1.969 1.969 1.969 0 0 0-.505-1.312 1.969 1.969 0 0 0-1.463-3.281Zm0 5.25H10.5v-1.313h.656a.656.656 0 0 1 0 1.313Zm0-2.625H10.5V8.53h.656a.656.656 0 1 1 0 1.313Z"
                    fill="currentColor"></path>
                  <path
                    d="M19.031 11.156a.656.656 0 1 0 0-1.312h-1.968V7.219h1.968a.656.656 0 1 0 0-1.313h-1.968a2.073 2.073 0 0 0-1.97-1.968v-1.97a.656.656 0 0 0-1.312 0v1.97h-2.625v-1.97a.656.656 0 1 0-1.312 0v1.97H7.219v-1.97a.656.656 0 1 0-1.313 0v1.97a2.074 2.074 0 0 0-1.968 1.968h-1.97a.656.656 0 1 0 0 1.313h1.97v2.625h-1.97a.656.656 0 1 0 0 1.312h1.97v2.625h-1.97a.656.656 0 0 0 0 1.313h1.97a2.073 2.073 0 0 0 1.968 1.969v1.968a.656.656 0 1 0 1.313 0v-1.968h2.625v1.968a.656.656 0 1 0 1.312 0v-1.968h2.625v1.968a.656.656 0 0 0 1.313 0v-1.968a2.073 2.073 0 0 0 1.969-1.97h1.968a.656.656 0 0 0 0-1.312h-1.968v-2.625h1.968Zm-3.281 3.826a.774.774 0 0 1-.768.768H6.018a.774.774 0 0 1-.768-.768V6.018a.774.774 0 0 1 .768-.768h8.964a.774.774 0 0 1 .768.768v8.964Z"
                    fill="currentColor"></path>
                </svg>
              </div>
              <div
                class="bl-text-center bl-relative bl-left-28 lg:bl-left-auto lg:bl-top-40 bl-opacity-0 bl-duration-200 group-hover:bl-opacity-100">
                <h3 class="bl-font-title bl-font-bold bl-text-primary bl-text-sm/none bl-whitespace-nowrap">Counterparty
                </h3>
                <div class="bl-text-foreground bl-text-sm/normal bl-w-[140px] md:bl-w-[240px] bl-mt-3.5">Counterparty
                  {{ $t('m.szzc') }}</div>
              </div>
            </div>
          </div>
          <div class="bl-flex bl-flex-col lg:bl-flex-row bl-gap-[6px] lg:bl-gap-[9px]">
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
          </div>
          <div class="bl-w-14 bl-h-[15px] lg:bl-h-14 lg:bl-w-5 bl-relative">
            <div
              class="bl-absolute bl-flex lg:bl-flex-col bl-items-center bl-w-24 bl-h-16 bl-top-1/2 -bl-translate-y-1/2 lg:bl-h-28 lg:bl-w-16 lg:bl-top-0 lg:bl-translate-y-0 lg:bl-left-1/2 lg:-bl-translate-x-1/2">
              <div
                class="bl-w-8 bl-h-0.5 lg:bl-h-14 lg:bl-w-0.5 bl-bg-secondary bl-absolute bl-duration-200 group-hover:bl-w-24 lg:group-hover:bl-w-0.5 lg:group-hover:bl-h-28 group-hover:bl-bg-primary">
              </div>
              <div
                class="bl-absolute bl-p-5 bl-right-1 lg:bl-right-auto lg:bl-bottom-1 bl-duration-200 group-hover:bl-translate-x-10 lg:group-hover:bl-translate-x-0 lg:group-hover:bl-translate-y-14">
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none"
                  class="bl-h-[21px] bl-w-[21px]">
                  <path
                    d="M11.156 7.219H8.531a.656.656 0 1 0 0 1.312h.656v3.938h-.656a.656.656 0 1 0 0 1.312h2.625a1.969 1.969 0 0 0 1.969-1.969 1.969 1.969 0 0 0-.505-1.312 1.969 1.969 0 0 0-1.463-3.281Zm0 5.25H10.5v-1.313h.656a.656.656 0 0 1 0 1.313Zm0-2.625H10.5V8.53h.656a.656.656 0 1 1 0 1.313Z"
                    fill="currentColor"></path>
                  <path
                    d="M19.031 11.156a.656.656 0 1 0 0-1.312h-1.968V7.219h1.968a.656.656 0 1 0 0-1.313h-1.968a2.073 2.073 0 0 0-1.97-1.968v-1.97a.656.656 0 0 0-1.312 0v1.97h-2.625v-1.97a.656.656 0 1 0-1.312 0v1.97H7.219v-1.97a.656.656 0 1 0-1.313 0v1.97a2.074 2.074 0 0 0-1.968 1.968h-1.97a.656.656 0 1 0 0 1.313h1.97v2.625h-1.97a.656.656 0 1 0 0 1.312h1.97v2.625h-1.97a.656.656 0 0 0 0 1.313h1.97a2.073 2.073 0 0 0 1.968 1.969v1.968a.656.656 0 1 0 1.313 0v-1.968h2.625v1.968a.656.656 0 1 0 1.312 0v-1.968h2.625v1.968a.656.656 0 0 0 1.313 0v-1.968a2.073 2.073 0 0 0 1.969-1.97h1.968a.656.656 0 0 0 0-1.312h-1.968v-2.625h1.968Zm-3.281 3.826a.774.774 0 0 1-.768.768H6.018a.774.774 0 0 1-.768-.768V6.018a.774.774 0 0 1 .768-.768h8.964a.774.774 0 0 1 .768.768v8.964Z"
                    fill="currentColor"></path>
                </svg>
              </div>
            </div>
          </div>
          <div class="bl-flex bl-flex-col lg:bl-flex-row bl-gap-[6px] lg:bl-gap-[9px]">
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
          </div>
          <div class="bl-w-14 bl-h-[15px] lg:bl-h-14 lg:bl-w-5 bl-relative">
            <div id="time3"
              class="bl-absolute bl-flex lg:bl-flex-col bl-items-center bl-w-24 bl-h-16 bl-top-1/2 -bl-translate-y-1/2 lg:bl-h-28 lg:bl-w-16 lg:bl-top-0 lg:bl-translate-y-0 lg:bl-left-1/2 lg:-bl-translate-x-1/2 bl-group">
              <div
                class="bl-text-foreground bl-text-base/normal bl-absolute -bl-left-12 lg:bl-left-auto lg:-bl-top-11 bl-py-4 bl-duration-200 group-hover:bl-text-[18px]/none group-hover:bl-text-white">
                2015{{ $t('m.year') }}</div>
              <div
                class="bl-w-8 bl-h-0.5 lg:bl-h-14 lg:bl-w-0.5 bl-bg-secondary bl-absolute bl-duration-200 group-hover:bl-w-24 lg:group-hover:bl-w-0.5 lg:group-hover:bl-h-28 group-hover:bl-bg-primary">
              </div>
              <div
                class="bl-absolute bl-p-5 bl-right-1 lg:bl-right-auto lg:bl-bottom-1 bl-duration-200 group-hover:bl-translate-x-10 lg:group-hover:bl-translate-x-0 lg:group-hover:bl-translate-y-14">
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none"
                  class="bl-h-[21px] bl-w-[21px]">
                  <path
                    d="M17.384 1.313h-7.54a2.317 2.317 0 0 0-1.628.656L1.97 8.243a2.264 2.264 0 0 0-.656 1.6v7.541a2.304 2.304 0 0 0 2.303 2.303h7.54a2.29 2.29 0 0 0 1.628-.656l6.247-6.273a2.264 2.264 0 0 0 .657-1.602v-7.54a2.303 2.303 0 0 0-2.304-2.303Zm.991 9.843a.998.998 0 0 1-.289.656l-6.273 6.274a.998.998 0 0 1-.657.289h-7.54a.997.997 0 0 1-.991-.99V9.843a.998.998 0 0 1 .289-.656l6.274-6.274a.998.998 0 0 1 .656-.289h7.54a.998.998 0 0 1 .991.991v7.54Z"
                    fill="currentColor"></path>
                  <path
                    d="M15.094 3.938h-2.625a.656.656 0 1 0 0 1.312h.656v3.938h-.656a.656.656 0 1 0 0 1.312h2.625a1.969 1.969 0 0 0 1.969-1.969 1.969 1.969 0 0 0-.506-1.312c.325-.36.504-.828.506-1.313a1.969 1.969 0 0 0-1.97-1.968Zm0 5.25h-.656V7.875h.656a.656.656 0 0 1 0 1.313Zm0-2.626h-.656V5.25h.656a.656.656 0 0 1 0 1.313ZM8.124 11.957l-3.28 2.625a.656.656 0 1 0 .82 1.024l3.28-2.625a.656.656 0 0 0-.82-1.024ZM5.906 11.156c.15 0 .297-.05.414-.144L9.6 8.387a.656.656 0 0 0-.82-1.024L5.499 9.988a.656.656 0 0 0 .407 1.168ZM12.718 12.613l-3.281 2.625a.656.656 0 1 0 .82 1.024l3.282-2.625a.656.656 0 0 0-.82-1.024Z"
                    fill="currentColor"></path>
                </svg>
              </div>
              <div
                class="bl-text-center bl-relative bl-left-28 lg:bl-left-auto lg:bl-top-40 bl-opacity-0 bl-duration-200 group-hover:bl-opacity-100">
                <h3 class="bl-font-title bl-font-bold bl-text-primary bl-text-sm/none bl-whitespace-nowrap">Spells of
                  Genesis</h3>
                <div class="bl-text-foreground bl-text-sm/normal bl-w-[140px] md:bl-w-[240px] bl-mt-3.5">
                  {{$t('m.jh')}}</div>
              </div>
            </div>
          </div>
          <div class="bl-flex bl-flex-col lg:bl-flex-row bl-gap-[6px] lg:bl-gap-[9px]">
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
          </div>
          <div class="bl-w-14 bl-h-[15px] lg:bl-h-14 lg:bl-w-5 bl-relative">
            <div
              class="bl-absolute bl-flex lg:bl-flex-col bl-items-center bl-w-24 bl-h-16 bl-top-1/2 -bl-translate-y-1/2 lg:bl-h-28 lg:bl-w-16 lg:bl-top-0 lg:bl-translate-y-0 lg:bl-left-1/2 lg:-bl-translate-x-1/2">
              <div
                class="bl-w-8 bl-h-0.5 lg:bl-h-14 lg:bl-w-0.5 bl-bg-secondary bl-absolute bl-duration-200 group-hover:bl-w-24 lg:group-hover:bl-w-0.5 lg:group-hover:bl-h-28 group-hover:bl-bg-primary">
              </div>
              <div
                class="bl-absolute bl-p-5 bl-right-1 lg:bl-right-auto lg:bl-bottom-1 bl-duration-200 group-hover:bl-translate-x-10 lg:group-hover:bl-translate-x-0 lg:group-hover:bl-translate-y-14">
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none"
                  class="bl-h-[21px] bl-w-[21px]">
                  <path
                    d="M17.384 1.313h-7.54a2.317 2.317 0 0 0-1.628.656L1.97 8.243a2.264 2.264 0 0 0-.656 1.6v7.541a2.304 2.304 0 0 0 2.303 2.303h7.54a2.29 2.29 0 0 0 1.628-.656l6.247-6.273a2.264 2.264 0 0 0 .657-1.602v-7.54a2.303 2.303 0 0 0-2.304-2.303Zm.991 9.843a.998.998 0 0 1-.289.656l-6.273 6.274a.998.998 0 0 1-.657.289h-7.54a.997.997 0 0 1-.991-.99V9.843a.998.998 0 0 1 .289-.656l6.274-6.274a.998.998 0 0 1 .656-.289h7.54a.998.998 0 0 1 .991.991v7.54Z"
                    fill="currentColor"></path>
                  <path
                    d="M15.094 3.938h-2.625a.656.656 0 1 0 0 1.312h.656v3.938h-.656a.656.656 0 1 0 0 1.312h2.625a1.969 1.969 0 0 0 1.969-1.969 1.969 1.969 0 0 0-.506-1.312c.325-.36.504-.828.506-1.313a1.969 1.969 0 0 0-1.97-1.968Zm0 5.25h-.656V7.875h.656a.656.656 0 0 1 0 1.313Zm0-2.626h-.656V5.25h.656a.656.656 0 0 1 0 1.313ZM8.124 11.957l-3.28 2.625a.656.656 0 1 0 .82 1.024l3.28-2.625a.656.656 0 0 0-.82-1.024ZM5.906 11.156c.15 0 .297-.05.414-.144L9.6 8.387a.656.656 0 0 0-.82-1.024L5.499 9.988a.656.656 0 0 0 .407 1.168ZM12.718 12.613l-3.281 2.625a.656.656 0 1 0 .82 1.024l3.282-2.625a.656.656 0 0 0-.82-1.024Z"
                    fill="currentColor"></path>
                </svg>
              </div>
            </div>
          </div>
          <div class="bl-flex bl-flex-col lg:bl-flex-row bl-gap-[6px] lg:bl-gap-[9px]">
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
          </div>
          <div class="bl-w-14 bl-h-[15px] lg:bl-h-14 lg:bl-w-5 bl-relative">
            <div id="time4"
              class="bl-absolute bl-flex lg:bl-flex-col bl-items-center bl-w-24 bl-h-16 bl-top-1/2 -bl-translate-y-1/2 lg:bl-h-28 lg:bl-w-16 lg:bl-top-0 lg:bl-translate-y-0 lg:bl-left-1/2 lg:-bl-translate-x-1/2 bl-group">
              <div
                class="bl-text-foreground bl-text-base/normal bl-absolute -bl-left-12 lg:bl-left-auto lg:-bl-top-11 bl-py-4 bl-duration-200 group-hover:bl-text-[18px]/none group-hover:bl-text-white">
                2016{{ $t('m.year') }}</div>
              <div
                class="bl-w-8 bl-h-0.5 lg:bl-h-14 lg:bl-w-0.5 bl-bg-secondary bl-absolute bl-duration-200 group-hover:bl-w-24 lg:group-hover:bl-w-0.5 lg:group-hover:bl-h-28 group-hover:bl-bg-primary">
              </div>
              <div
                class="bl-absolute bl-p-5 bl-right-1 lg:bl-right-auto lg:bl-bottom-1 bl-duration-200 group-hover:bl-translate-x-10 lg:group-hover:bl-translate-x-0 lg:group-hover:bl-translate-y-14">
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none"
                  class="bl-h-[21px] bl-w-[21px]">
                  <path
                    d="M17.719 13.63a7.725 7.725 0 0 0 .656-3.13 7.875 7.875 0 0 0-5.729-7.58.604.604 0 0 0-.217 0 1.969 1.969 0 0 0-3.858 0 .603.603 0 0 0-.217 0 7.875 7.875 0 0 0-5.729 7.58 7.725 7.725 0 0 0 .656 3.13 1.969 1.969 0 0 0-.656 1.464 1.969 1.969 0 0 0 3.065 1.634 7.875 7.875 0 0 0 9.62 0 1.969 1.969 0 0 0 2.409-3.098ZM10.5 2.625a.656.656 0 1 1 0 1.312.656.656 0 0 1 0-1.312ZM4.594 15.75a.656.656 0 1 1 0-1.311.656.656 0 0 1 0 1.311Zm1.87-.079a1.87 1.87 0 0 0 .099-.577 1.969 1.969 0 0 0-1.97-1.969h-.091a6.424 6.424 0 0 1-.564-2.625 6.562 6.562 0 0 1 4.77-6.313h.04a1.969 1.969 0 0 0 3.504 0h.04a6.563 6.563 0 0 1 4.77 6.313 6.45 6.45 0 0 1-.564 2.625h-.092a1.97 1.97 0 0 0-1.968 1.969c.002.196.035.391.098.577a6.563 6.563 0 0 1-8.072 0Zm9.942.079a.655.655 0 1 1 0-1.311.655.655 0 0 1 0 1.311Z"
                    fill="currentColor"></path>
                  <path
                    d="M11.701 7.875a1.411 1.411 0 0 0-2.402 0l-1.91 3.084a1.411 1.411 0 0 0 1.195 2.166h3.833a1.41 1.41 0 0 0 1.194-2.166L11.7 7.875Zm.8 3.859a.091.091 0 0 1-.084.052H8.584a.092.092 0 0 1-.085-.052.092.092 0 0 1 0-.105l1.916-3.058a.098.098 0 0 1 .17 0l1.917 3.058a.093.093 0 0 1 .027.065.093.093 0 0 1-.027.066v-.026Z"
                    fill="currentColor"></path>
                </svg>
              </div>
              <div
                class="bl-text-center bl-relative bl-left-28 lg:bl-left-auto lg:bl-top-40 bl-opacity-0 bl-duration-200 group-hover:bl-opacity-100">
                <h3 class="bl-font-title bl-font-bold bl-text-primary bl-text-sm/none bl-whitespace-nowrap">Rare PEPE
                </h3>
                <div class="bl-text-foreground bl-text-sm/normal bl-w-[140px] md:bl-w-[240px] bl-mt-3.5">{{ $t('m.ys') }}</div>
              </div>
            </div>
          </div>
          <div class="bl-flex bl-flex-col lg:bl-flex-row bl-gap-[6px] lg:bl-gap-[9px]">
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
          </div>
          <div class="bl-w-14 bl-h-[15px] lg:bl-h-14 lg:bl-w-5 bl-relative">
            <div
              class="bl-absolute bl-flex lg:bl-flex-col bl-items-center bl-w-24 bl-h-16 bl-top-1/2 -bl-translate-y-1/2 lg:bl-h-28 lg:bl-w-16 lg:bl-top-0 lg:bl-translate-y-0 lg:bl-left-1/2 lg:-bl-translate-x-1/2">
              <div
                class="bl-w-8 bl-h-0.5 lg:bl-h-14 lg:bl-w-0.5 bl-bg-secondary bl-absolute bl-duration-200 group-hover:bl-w-24 lg:group-hover:bl-w-0.5 lg:group-hover:bl-h-28 group-hover:bl-bg-primary">
              </div>
              <div
                class="bl-absolute bl-p-5 bl-right-1 lg:bl-right-auto lg:bl-bottom-1 bl-duration-200 group-hover:bl-translate-x-10 lg:group-hover:bl-translate-x-0 lg:group-hover:bl-translate-y-14">
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none"
                  class="bl-h-[21px] bl-w-[21px]">
                  <path
                    d="M17.719 13.63a7.725 7.725 0 0 0 .656-3.13 7.875 7.875 0 0 0-5.729-7.58.604.604 0 0 0-.217 0 1.969 1.969 0 0 0-3.858 0 .603.603 0 0 0-.217 0 7.875 7.875 0 0 0-5.729 7.58 7.725 7.725 0 0 0 .656 3.13 1.969 1.969 0 0 0-.656 1.464 1.969 1.969 0 0 0 3.065 1.634 7.875 7.875 0 0 0 9.62 0 1.969 1.969 0 0 0 2.409-3.098ZM10.5 2.625a.656.656 0 1 1 0 1.312.656.656 0 0 1 0-1.312ZM4.594 15.75a.656.656 0 1 1 0-1.311.656.656 0 0 1 0 1.311Zm1.87-.079a1.87 1.87 0 0 0 .099-.577 1.969 1.969 0 0 0-1.97-1.969h-.091a6.424 6.424 0 0 1-.564-2.625 6.562 6.562 0 0 1 4.77-6.313h.04a1.969 1.969 0 0 0 3.504 0h.04a6.563 6.563 0 0 1 4.77 6.313 6.45 6.45 0 0 1-.564 2.625h-.092a1.97 1.97 0 0 0-1.968 1.969c.002.196.035.391.098.577a6.563 6.563 0 0 1-8.072 0Zm9.942.079a.655.655 0 1 1 0-1.311.655.655 0 0 1 0 1.311Z"
                    fill="currentColor"></path>
                  <path
                    d="M11.701 7.875a1.411 1.411 0 0 0-2.402 0l-1.91 3.084a1.411 1.411 0 0 0 1.195 2.166h3.833a1.41 1.41 0 0 0 1.194-2.166L11.7 7.875Zm.8 3.859a.091.091 0 0 1-.084.052H8.584a.092.092 0 0 1-.085-.052.092.092 0 0 1 0-.105l1.916-3.058a.098.098 0 0 1 .17 0l1.917 3.058a.093.093 0 0 1 .027.065.093.093 0 0 1-.027.066v-.026Z"
                    fill="currentColor"></path>
                </svg>
              </div>
            </div>
          </div>
          <div class="bl-flex bl-flex-col lg:bl-flex-row bl-gap-[6px] lg:bl-gap-[9px]">
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
          </div>
          <div class="bl-w-14 bl-h-[15px] lg:bl-h-14 lg:bl-w-5 bl-relative">
            <div id="time5"
              class="bl-absolute bl-flex lg:bl-flex-col bl-items-center bl-w-24 bl-h-16 bl-top-1/2 -bl-translate-y-1/2 lg:bl-h-28 lg:bl-w-16 lg:bl-top-0 lg:bl-translate-y-0 lg:bl-left-1/2 lg:-bl-translate-x-1/2 bl-group">
              <div
                class="bl-text-foreground bl-text-base/normal bl-absolute -bl-left-12 lg:bl-left-auto lg:-bl-top-11 bl-py-4 bl-duration-200 group-hover:bl-text-[18px]/none group-hover:bl-text-white">
                2017{{ $t('m.year') }}</div>
              <div
                class="bl-w-8 bl-h-0.5 lg:bl-h-14 lg:bl-w-0.5 bl-bg-secondary bl-absolute bl-duration-200 group-hover:bl-w-24 lg:group-hover:bl-w-0.5 lg:group-hover:bl-h-28 group-hover:bl-bg-primary">
              </div>
              <div
                class="bl-absolute bl-p-5 bl-right-1 lg:bl-right-auto lg:bl-bottom-1 bl-duration-200 group-hover:bl-translate-x-10 lg:group-hover:bl-translate-x-0 lg:group-hover:bl-translate-y-14">
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none"
                  class="bl-h-[21px] bl-w-[21px]">
                  <path
                    d="M11.156 7.219H8.531a.656.656 0 1 0 0 1.312h.656v3.938h-.656a.656.656 0 1 0 0 1.312h2.625a1.969 1.969 0 0 0 1.969-1.969 1.969 1.969 0 0 0-.505-1.312 1.969 1.969 0 0 0-1.463-3.281Zm0 5.25H10.5v-1.313h.656a.656.656 0 0 1 0 1.313Zm0-2.625H10.5V8.53h.656a.656.656 0 1 1 0 1.313ZM9.188 3.938h2.624a.656.656 0 1 0 0-1.313H9.188a.656.656 0 1 0 0 1.313ZM11.813 17.063H9.187a.656.656 0 1 0 0 1.312h2.626a.656.656 0 1 0 0-1.313ZM13.125 5.906h2.625a.656.656 0 1 0 0-1.312h-2.625a.656.656 0 1 0 0 1.312ZM5.25 5.906h2.625a.656.656 0 1 0 0-1.312H5.25a.656.656 0 1 0 0 1.312ZM15.75 15.094h-2.625a.656.656 0 1 0 0 1.312h2.625a.656.656 0 1 0 0-1.312ZM7.875 15.094H5.25a.656.656 0 1 0 0 1.312h2.625a.656.656 0 1 0 0-1.312ZM5.25 10.5a.656.656 0 0 0-.656-.656H1.969a.656.656 0 1 0 0 1.312h2.625a.656.656 0 0 0 .656-.656ZM19.031 9.844h-2.625a.656.656 0 0 0 0 1.312h2.625a.656.656 0 1 0 0-1.312ZM3.281 8.531h2.625a.656.656 0 1 0 0-1.312H3.281a.656.656 0 1 0 0 1.312ZM14.438 7.875a.656.656 0 0 0 .656.656h2.625a.656.656 0 0 0 0-1.312h-2.625a.656.656 0 0 0-.656.656ZM6.563 13.125a.656.656 0 0 0-.657-.656H3.281a.656.656 0 1 0 0 1.312h2.625a.656.656 0 0 0 .657-.656ZM17.719 12.469h-2.625a.656.656 0 1 0 0 1.312h2.625a.656.656 0 1 0 0-1.312Z"
                    fill="currentColor"></path>
                </svg>
              </div>
              <div
                class="bl-text-center bl-relative bl-left-28 lg:bl-left-auto lg:bl-top-40 bl-opacity-0 bl-duration-200 group-hover:bl-opacity-100">
                <h3 class="bl-font-title bl-font-bold bl-text-primary bl-text-sm/none bl-whitespace-nowrap">{{$t('m.jzsj')}}</h3>
                <div class="bl-text-foreground bl-text-sm/normal bl-w-[140px] md:bl-w-[240px] bl-mt-3.5">
                  {{$t('m.kkzx')}}</div>
              </div>
            </div>
          </div>
          <div class="bl-flex bl-flex-col lg:bl-flex-row bl-gap-[6px] lg:bl-gap-[9px]">
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
          </div>
          <div class="bl-w-14 bl-h-[15px] lg:bl-h-14 lg:bl-w-5 bl-relative">
            <div
              class="bl-absolute bl-flex lg:bl-flex-col bl-items-center bl-w-24 bl-h-16 bl-top-1/2 -bl-translate-y-1/2 lg:bl-h-28 lg:bl-w-16 lg:bl-top-0 lg:bl-translate-y-0 lg:bl-left-1/2 lg:-bl-translate-x-1/2">
              <div
                class="bl-w-8 bl-h-0.5 lg:bl-h-14 lg:bl-w-0.5 bl-bg-secondary bl-absolute bl-duration-200 group-hover:bl-w-24 lg:group-hover:bl-w-0.5 lg:group-hover:bl-h-28 group-hover:bl-bg-primary">
              </div>
              <div
                class="bl-absolute bl-p-5 bl-right-1 lg:bl-right-auto lg:bl-bottom-1 bl-duration-200 group-hover:bl-translate-x-10 lg:group-hover:bl-translate-x-0 lg:group-hover:bl-translate-y-14">
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none"
                  class="bl-h-[21px] bl-w-[21px]">
                  <path
                    d="M11.156 7.219H8.531a.656.656 0 1 0 0 1.312h.656v3.938h-.656a.656.656 0 1 0 0 1.312h2.625a1.969 1.969 0 0 0 1.969-1.969 1.969 1.969 0 0 0-.505-1.312 1.969 1.969 0 0 0-1.463-3.281Zm0 5.25H10.5v-1.313h.656a.656.656 0 0 1 0 1.313Zm0-2.625H10.5V8.53h.656a.656.656 0 1 1 0 1.313ZM9.188 3.938h2.624a.656.656 0 1 0 0-1.313H9.188a.656.656 0 1 0 0 1.313ZM11.813 17.063H9.187a.656.656 0 1 0 0 1.312h2.626a.656.656 0 1 0 0-1.313ZM13.125 5.906h2.625a.656.656 0 1 0 0-1.312h-2.625a.656.656 0 1 0 0 1.312ZM5.25 5.906h2.625a.656.656 0 1 0 0-1.312H5.25a.656.656 0 1 0 0 1.312ZM15.75 15.094h-2.625a.656.656 0 1 0 0 1.312h2.625a.656.656 0 1 0 0-1.312ZM7.875 15.094H5.25a.656.656 0 1 0 0 1.312h2.625a.656.656 0 1 0 0-1.312ZM5.25 10.5a.656.656 0 0 0-.656-.656H1.969a.656.656 0 1 0 0 1.312h2.625a.656.656 0 0 0 .656-.656ZM19.031 9.844h-2.625a.656.656 0 0 0 0 1.312h2.625a.656.656 0 1 0 0-1.312ZM3.281 8.531h2.625a.656.656 0 1 0 0-1.312H3.281a.656.656 0 1 0 0 1.312ZM14.438 7.875a.656.656 0 0 0 .656.656h2.625a.656.656 0 0 0 0-1.312h-2.625a.656.656 0 0 0-.656.656ZM6.563 13.125a.656.656 0 0 0-.657-.656H3.281a.656.656 0 1 0 0 1.312h2.625a.656.656 0 0 0 .657-.656ZM17.719 12.469h-2.625a.656.656 0 1 0 0 1.312h2.625a.656.656 0 1 0 0-1.312Z"
                    fill="currentColor"></path>
                </svg>
              </div>
            </div>
          </div>
          <div class="bl-flex bl-flex-col lg:bl-flex-row bl-gap-[6px] lg:bl-gap-[9px]">
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
          </div>
          <div class="bl-w-14 bl-h-[15px] lg:bl-h-14 lg:bl-w-5 bl-relative">
            <div id="time6"
              class="bl-absolute bl-flex lg:bl-flex-col bl-items-center bl-w-24 bl-h-16 bl-top-1/2 -bl-translate-y-1/2 lg:bl-h-28 lg:bl-w-16 lg:bl-top-0 lg:bl-translate-y-0 lg:bl-left-1/2 lg:-bl-translate-x-1/2 bl-group">
              <div
                class="bl-text-foreground bl-text-base/normal bl-absolute -bl-left-12 lg:bl-left-auto lg:-bl-top-11 bl-py-4 bl-duration-200 group-hover:bl-text-[18px]/none group-hover:bl-text-white">
                2021{{ $t('m.year') }}</div>
              <div
                class="bl-w-8 bl-h-0.5 lg:bl-h-14 lg:bl-w-0.5 bl-bg-secondary bl-absolute bl-duration-200 group-hover:bl-w-24 lg:group-hover:bl-w-0.5 lg:group-hover:bl-h-28 group-hover:bl-bg-primary">
              </div>
              <div
                class="bl-absolute bl-p-5 bl-right-1 lg:bl-right-auto lg:bl-bottom-1 bl-duration-200 group-hover:bl-translate-x-10 lg:group-hover:bl-translate-x-0 lg:group-hover:bl-translate-y-14">
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none"
                  class="bl-h-[21px] bl-w-[21px]">
                  <path
                    d="M18.25 4.515a.656.656 0 0 0-.656-.577 9.24 9.24 0 0 1-3.623-.617 9.595 9.595 0 0 1-3.018-1.838.656.656 0 0 0-.88 0 9.732 9.732 0 0 1-3.018 1.844 9.483 9.483 0 0 1-3.636.61.656.656 0 0 0-.656.578c-.243 1.969-.657 8.813 4.088 13.04a11.47 11.47 0 0 0 3.42 2.087c.155.062.329.062.485 0a11.47 11.47 0 0 0 3.419-2.087C18.9 13.329 18.493 6.51 18.25 4.515ZM13.29 16.57a9.928 9.928 0 0 1-2.79 1.753 9.928 9.928 0 0 1-2.788-1.753C3.773 13.086 3.832 7.383 3.996 5.25A10.775 10.775 0 0 0 10.5 2.828a10.723 10.723 0 0 0 2.992 1.72c1.122.431 2.31.668 3.511.702.164 2.1.204 7.836-3.714 11.32Z"
                    fill="currentColor"></path>
                  <path
                    d="M11.156 7.219H8.531a.656.656 0 1 0 0 1.312h.656v3.938h-.656a.656.656 0 1 0 0 1.312h2.625a1.969 1.969 0 0 0 1.969-1.969 1.969 1.969 0 0 0-.505-1.312 1.969 1.969 0 0 0-1.463-3.281Zm0 5.25H10.5v-1.313h.656a.656.656 0 0 1 0 1.313Zm0-2.625H10.5V8.53h.656a.656.656 0 1 1 0 1.313Z"
                    fill="currentColor"></path>
                </svg>
              </div>
              <div
                class="bl-text-center bl-relative bl-left-28 lg:bl-left-auto lg:bl-top-40 bl-opacity-0 bl-duration-200 group-hover:bl-opacity-100">
                <h3 class="bl-font-title bl-font-bold bl-text-primary bl-text-sm/none bl-whitespace-nowrap">{{$t('m.tsj')}}
                </h3>
                <div class="bl-text-foreground bl-text-sm/normal bl-w-[140px] md:bl-w-[240px] bl-mt-3.5">Taproot
                  {{ $t('m.hygn') }}</div>
              </div>
            </div>
          </div>
          <div class="bl-flex bl-flex-col lg:bl-flex-row bl-gap-[6px] lg:bl-gap-[9px]">
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
          </div>
          <div class="bl-w-14 bl-h-[15px] lg:bl-h-14 lg:bl-w-5 bl-relative">
            <div
              class="bl-absolute bl-flex lg:bl-flex-col bl-items-center bl-w-24 bl-h-16 bl-top-1/2 -bl-translate-y-1/2 lg:bl-h-28 lg:bl-w-16 lg:bl-top-0 lg:bl-translate-y-0 lg:bl-left-1/2 lg:-bl-translate-x-1/2">
              <div
                class="bl-w-8 bl-h-0.5 lg:bl-h-14 lg:bl-w-0.5 bl-bg-secondary bl-absolute bl-duration-200 group-hover:bl-w-24 lg:group-hover:bl-w-0.5 lg:group-hover:bl-h-28 group-hover:bl-bg-primary">
              </div>
              <div
                class="bl-absolute bl-p-5 bl-right-1 lg:bl-right-auto lg:bl-bottom-1 bl-duration-200 group-hover:bl-translate-x-10 lg:group-hover:bl-translate-x-0 lg:group-hover:bl-translate-y-14">
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none"
                  class="bl-h-[21px] bl-w-[21px]">
                  <path
                    d="M18.25 4.515a.656.656 0 0 0-.656-.577 9.24 9.24 0 0 1-3.623-.617 9.595 9.595 0 0 1-3.018-1.838.656.656 0 0 0-.88 0 9.732 9.732 0 0 1-3.018 1.844 9.483 9.483 0 0 1-3.636.61.656.656 0 0 0-.656.578c-.243 1.969-.657 8.813 4.088 13.04a11.47 11.47 0 0 0 3.42 2.087c.155.062.329.062.485 0a11.47 11.47 0 0 0 3.419-2.087C18.9 13.329 18.493 6.51 18.25 4.515ZM13.29 16.57a9.928 9.928 0 0 1-2.79 1.753 9.928 9.928 0 0 1-2.788-1.753C3.773 13.086 3.832 7.383 3.996 5.25A10.775 10.775 0 0 0 10.5 2.828a10.723 10.723 0 0 0 2.992 1.72c1.122.431 2.31.668 3.511.702.164 2.1.204 7.836-3.714 11.32Z"
                    fill="currentColor"></path>
                  <path
                    d="M11.156 7.219H8.531a.656.656 0 1 0 0 1.312h.656v3.938h-.656a.656.656 0 1 0 0 1.312h2.625a1.969 1.969 0 0 0 1.969-1.969 1.969 1.969 0 0 0-.505-1.312 1.969 1.969 0 0 0-1.463-3.281Zm0 5.25H10.5v-1.313h.656a.656.656 0 0 1 0 1.313Zm0-2.625H10.5V8.53h.656a.656.656 0 1 1 0 1.313Z"
                    fill="currentColor"></path>
                </svg>
              </div>
            </div>
          </div>
          <div class="bl-flex bl-flex-col lg:bl-flex-row bl-gap-[6px] lg:bl-gap-[9px]">
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
          </div>
          <div class="bl-w-14 bl-h-[15px] lg:bl-h-14 lg:bl-w-5 bl-relative">
            <div id="time7"
              class="bl-absolute bl-flex lg:bl-flex-col bl-items-center bl-w-24 bl-h-16 bl-top-1/2 -bl-translate-y-1/2 lg:bl-h-28 lg:bl-w-16 lg:bl-top-0 lg:bl-translate-y-0 lg:bl-left-1/2 lg:-bl-translate-x-1/2 bl-group">
              <div
                class="bl-text-foreground bl-text-base/normal bl-absolute -bl-left-12 lg:bl-left-auto lg:-bl-top-11 bl-py-4 bl-duration-200 group-hover:bl-text-[18px]/none group-hover:bl-text-white">
                2023{{ $t('m.year') }}</div>
              <div
                class="bl-w-8 bl-h-0.5 lg:bl-h-14 lg:bl-w-0.5 bl-bg-secondary bl-absolute bl-duration-200 group-hover:bl-w-24 lg:group-hover:bl-w-0.5 lg:group-hover:bl-h-28 group-hover:bl-bg-primary">
              </div>
              <div
                class="bl-absolute bl-p-5 bl-right-1 lg:bl-right-auto lg:bl-bottom-1 bl-duration-200 group-hover:bl-translate-x-10 lg:group-hover:bl-translate-x-0 lg:group-hover:bl-translate-y-14">
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none"
                  class="bl-h-[21px] bl-w-[21px]">
                  <path
                    d="M17.765 4.594H3.235a1.923 1.923 0 0 0-1.922 1.923v7.966a1.923 1.923 0 0 0 1.922 1.923h14.53a1.923 1.923 0 0 0 1.922-1.923V6.517a1.923 1.923 0 0 0-1.922-1.923Zm.61 9.89a.61.61 0 0 1-.61.61H3.235a.61.61 0 0 1-.61-.61V6.516a.61.61 0 0 1 .61-.61h14.53a.61.61 0 0 1 .61.61v7.966Z"
                    fill="currentColor"></path>
                  <path
                    d="M11.156 7.219H8.531a.656.656 0 1 0 0 1.312h.656v3.938h-.656a.656.656 0 1 0 0 1.312h2.625a1.969 1.969 0 0 0 1.969-1.969 1.969 1.969 0 0 0-.505-1.312 1.969 1.969 0 0 0-1.463-3.281Zm0 5.25H10.5v-1.313h.656a.656.656 0 0 1 0 1.313Zm0-2.625H10.5V8.53h.656a.656.656 0 1 1 0 1.313ZM16.406 7.219h-1.312a.656.656 0 1 0 0 1.312h1.312a.656.656 0 1 0 0-1.312ZM5.906 12.469H4.594a.656.656 0 1 0 0 1.312h1.312a.656.656 0 1 0 0-1.312Z"
                    fill="currentColor"></path>
                </svg>
              </div>
              <div
                class="bl-text-center bl-relative bl-left-28 lg:bl-left-auto lg:bl-top-40 bl-opacity-0 bl-duration-200 group-hover:bl-opacity-100">
                <h3 class="bl-font-title bl-font-bold bl-text-primary bl-text-sm/none bl-whitespace-nowrap">Ordinals
                </h3>
                <div class="bl-text-foreground bl-text-sm/normal bl-w-[140px] md:bl-w-[240px] bl-mt-3.5">{{$t('m.xygn')}}。</div>
              </div>
            </div>
          </div>
          <div class="bl-flex bl-flex-col lg:bl-flex-row bl-gap-[6px] lg:bl-gap-[9px]">
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
            <div class="bl-w-4 bl-h-0.5 lg:bl-h-7 lg:bl-w-0.5 bl-bg-secondary"></div>
          </div>
          <div class="bl-w-14 bl-h-[15px] lg:bl-h-14 lg:bl-w-5 bl-relative">
            <div
              class="bl-absolute bl-flex lg:bl-flex-col bl-items-center bl-w-24 bl-h-16 bl-top-1/2 -bl-translate-y-1/2 lg:bl-h-28 lg:bl-w-16 lg:bl-top-0 lg:bl-translate-y-0 lg:bl-left-1/2 lg:-bl-translate-x-1/2">
              <div
                class="bl-w-8 bl-h-0.5 lg:bl-h-14 lg:bl-w-0.5 bl-bg-secondary bl-absolute bl-duration-200 group-hover:bl-w-24 lg:group-hover:bl-w-0.5 lg:group-hover:bl-h-28 group-hover:bl-bg-primary">
              </div>
              <div
                class="bl-absolute bl-p-5 bl-right-1 lg:bl-right-auto lg:bl-bottom-1 bl-duration-200 group-hover:bl-translate-x-10 lg:group-hover:bl-translate-x-0 lg:group-hover:bl-translate-y-14">
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none"
                  class="bl-h-[21px] bl-w-[21px]">
                  <path
                    d="M17.765 4.594H3.235a1.923 1.923 0 0 0-1.922 1.923v7.966a1.923 1.923 0 0 0 1.922 1.923h14.53a1.923 1.923 0 0 0 1.922-1.923V6.517a1.923 1.923 0 0 0-1.922-1.923Zm.61 9.89a.61.61 0 0 1-.61.61H3.235a.61.61 0 0 1-.61-.61V6.516a.61.61 0 0 1 .61-.61h14.53a.61.61 0 0 1 .61.61v7.966Z"
                    fill="currentColor"></path>
                  <path
                    d="M11.156 7.219H8.531a.656.656 0 1 0 0 1.312h.656v3.938h-.656a.656.656 0 1 0 0 1.312h2.625a1.969 1.969 0 0 0 1.969-1.969 1.969 1.969 0 0 0-.505-1.312 1.969 1.969 0 0 0-1.463-3.281Zm0 5.25H10.5v-1.313h.656a.656.656 0 0 1 0 1.313Zm0-2.625H10.5V8.53h.656a.656.656 0 1 1 0 1.313ZM16.406 7.219h-1.312a.656.656 0 1 0 0 1.312h1.312a.656.656 0 1 0 0-1.312ZM5.906 12.469H4.594a.656.656 0 1 0 0 1.312h1.312a.656.656 0 1 0 0-1.312Z"
                    fill="currentColor"></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="newcard">
      <div class="newcard1">{{$t('m.blc')}}</div>
      <div class="card-container">
        <div class="ccard" id="js-card1">
          <div class="ccard-c ccard-1">
            <div class="ccard-txt txt1-opacity">
              <div class="ccard-txt1">{{$t('m.test')}}</div>
              <div class="ccard-txt2">2025 Q1</div>
              <div class="ccard-txt3">{{$t('m.qd')}}</div>


            </div>

          </div>
        </div>
        <div class="ccard ff" id="js-card2">
          <div class="ccard-c ccard-2">
            <div class="ccard-txt txt2-opacity">
              <div class="ccard-txt1">{{$t('m.gw')}}</div>
              <div class="ccard-txt2">2025 Q2</div>
              <div class="ccard-txt3">{{$t('m.fbgt')}}</div>


            </div>

          </div>
        </div>
        <div class="ccard" id="js-card3">
          <div class="ccard-c ccard-3">
            <div class="ccard-txt txt3-opacity">
              <div class="ccard-txt1">{{$t('m.zw')}}</div>
              <div class="ccard-txt2">2025 Q3</div>
              <div class="ccard-txt3">{{$t('m.wl')}}</div>


            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="news" style="display: flex;justify-content: center;align-items: center;flex-direction: column;">
      <div>

        <div class="fa yellow" id="news">
          {{$t('m.news')}}
          <div class="jb"></div>
          <div class="jb2"></div>
        </div>


      </div>
      <div class="white w2 news-title">
        {{$t('m.news1')}}
      </div>
      <div class="white w3 news-title1">{{$t('m.zxjz')}}</div>

    </div>

    <div class="newcard card-news webnews">
      <el-carousel :interval="2000" type="card" :autoplay="false">
        <el-carousel-item class="item2 item21" style="background-color: #000;">
          <div class="card-news-img">
            <img style="object-fit: contain;" src="@/assets/e1.png" />
          </div>
          <div class="white card-news-xh">01</div>

        </el-carousel-item>

        <el-carousel-item class="item2 item22" style="background-color: #000;">
          <div class="card-news-img">
            <img style="object-fit: scale-down;" src="@/assets/e2.png" />
          </div>
          <div class="white card-news-xh">02</div>
        </el-carousel-item>

        <el-carousel-item class="item2 item23" style="background-color: #000;">
          <div class="card-news-img">
            <img src="@/assets/e3.png" />
          </div>
          <div class="white card-news-xh">03</div>
        </el-carousel-item>
        <!-- <el-carousel-item class="item2 item24" style="background-color: #000;">
          <div class="card-news-img">
            <img src="https://www.bitlayer.org/images/event-4.png" />
          </div>
          <div class="white card-news-xh">04</div>
        </el-carousel-item> -->
      </el-carousel>
    </div>


    <div class="newcard card-news phonenews">
      <el-carousel :interval="2000" :autoplay="true" indicator-position="outside">
        <el-carousel-item class="item2 item21" style="background-color: #000;">
          <div class="card-news-img">
            <img style="object-fit: contain;" src="@/assets/e1.png" />
          </div>


        </el-carousel-item>

        <el-carousel-item class="item2 item22" style="background-color: #000;">
          <div class="card-news-img">
            <img style="object-fit: scale-down;" src="@/assets/e2.png" />
          </div>

        </el-carousel-item>

        <el-carousel-item class="item2 item23" style="background-color: #000;">
          <div class="card-news-img">
            <img src="@/assets/e3.png" />
          </div>

        </el-carousel-item>
        <!-- <el-carousel-item class="item2 item24" style="background-color: #000;">
          <div class="card-news-img">
            <img src="https://www.bitlayer.org/images/event-4.png" />
          </div>

        </el-carousel-item> -->
      </el-carousel>
    </div>




    <section id="jobs" class="bl-w-screen bl-overflow-x-hidden bl-pt-16 bl-pb-24 bl-flex bl-flex-col bl-items-center">
      <div id="gzjh"
        class="bl-inline-flex bl-items-center bl-justify-center bl-transition-colors bl-relative bl-h-[55px] bl-min-w-[260px] bl-text-[26px]/none bl-bg-primary/20 bl-text-primary">
        <span>{{$t('m.gzjh')}}</span>
        <div class="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-t bl-top-0 bl-left-0"></div>
        <div class="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-b bl-right-0 bl-bottom-0">
        </div>
      </div>
      <h3 class="bl-font-title bl-font-bold bl-text-foreground bl-text-[45px]/[1.2] md:bl-text-[90px]/[1.1] bl-mt-8">
        BitFAST</h3>
      <h3 class="bl-font-title bl-font-bold bl-text-foreground bl-text-[45px]/[1.2] md:bl-text-[90px]/[1.1]">{{$t('m.zwkq')}}</h3>
      <div class="bl-text-foreground bl-text-base/normal md:bl-text-xl/normal bl-mt-5 bl-text-center">{{$t('m.history')}}
      </div>
      <div class="bl-container bl-flex bl-flex-col bl-gap-20 bl-w-full bl-mt-28 bl-mb-12">
        <div class="bl-flex bl-w-full bl-relative bl-justify-start job-position"
          style="transform: translateX(0%) translateZ(0px); opacity: 1;">
          <div class="bl-w-full lg:bl-w-3/5 lg:bl-max-w-[879px] bl-flex bl-flex-col bl-px-4">
            <div class="bl-min-h-[59px] lg:bl-h-[84px] bl-w-full bl-flex">
              <div
                class="bl-w-[49px] lg:bl-w-[164px] bl-h-full bl-bg-primary/20 bl-flex bl-items-center bl-justify-center bl-relative bl-min-w-0 bl-shrink-0">
                <div
                  class="bl-h-2 bl-w-2 lg:bl-h-3 lg:bl-w-3 bl-border-t bl-border-l bl-border-primary bl-absolute bl-top-0 bl-left-0">
                </div>
                <div
                  class="bl-h-2 bl-w-2 lg:bl-h-3 lg:bl-w-3 bl-border-b bl-border-l bl-border-primary bl-absolute bl-bottom-0 bl-left-0">
                </div>
                <div
                  class="bl-h-5 bl-w-5 lg:bl-h-10 lg:bl-w-10 bl-rounded-full bl-bg-primary bl-flex bl-items-center bl-justify-center">
                  <h3
                    class="bl-font-title bl-font-bold bl-text-black bl-text-[10px]/none md:bl-text-[10px]/none lg:bl-text-base/none">
                    01</h3>
                </div>
              </div><a href="#" target="_blank"
                class="bl-grow bl-h-full bl-bg-primary bl-px-5 lg:bl-px-11 bl-flex bl-items-center bl-justify-between bl-duration-200 bl-group hover:bl-bg-secondary"
                rel="noreferrer">
                <div class="bl-text-black bl-text-xl/none bl-py-1 lg:bl-text-[26px]/none">{{$t('m.fzr')}}</div><svg width="37"
                  height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg"
                  class="bl-text-black bl-h-7 bl-w-7 lg:bl-h-9 lg:bl-w-9 bl-duration-200 group-hover:bl-text-white">
                  <path
                    d="m31.597 24.644-.7-.372a1.292 1.292 0 0 1-.69-.994l-.238-2.02a1.938 1.938 0 0 0-1.245-1.589l-3.107-1.157a1.292 1.292 0 0 1-.747-.736l-1.372-3.498a1.645 1.645 0 0 1 1.292-2.232l2.397-.33a1.95 1.95 0 0 0 1.315-.796l1.106-1.55a14.776 14.776 0 0 0-11.534-5.53h-.238l.517 2.81a1.29 1.29 0 0 1-1.034 1.508c-.143.024-.29.024-.434 0a1.73 1.73 0 0 0-1.963 1.054l-.661 1.837a1.292 1.292 0 0 1-1.584.8l-1.198-.356a1.387 1.387 0 0 0-1.034.072 1.972 1.972 0 0 0-.821 2.796l1.32 2.17a1.948 1.948 0 0 0 1.656.93h2.428c.203-.001.403.046.584.139l2.462 1.248a1.29 1.29 0 0 1 .697.989l.072.566a1.781 1.781 0 0 0 .775 1.258l1.38.922a1.643 1.643 0 0 1 .268 2.51l-1.976 2.041c-.229.236-.393.527-.478.845l-1.464 5.425a14.797 14.797 0 0 0 14.249-8.76ZM11.25 31.978l.16-1.307a2.475 2.475 0 0 0-.423-1.708L8.58 25.497a1.496 1.496 0 0 1 .179-1.92.958.958 0 0 0 0-1.356.99.99 0 0 0-.202-.157L3.28 19.01a14.794 14.794 0 0 0 7.971 12.968Zm6.823 4.952a18.082 18.082 0 1 1 0-36.164 18.082 18.082 0 0 1 0 36.164Z"
                    fill="currentColor"></path>
                </svg>
              </a>
            </div>
            <div class="bl-w-full bl-flex">
              <div class="bl-hidden xl:bl-block lg:bl-w-[164px] bl-min-w-0 bl-shrink-0"></div>
              <div class="bl-grow bl-bg-[#101010] bl-border bl-border-[#1b1e21] bl-p-5 lg:bl-p-10 bl-space-y-2">
                <div class="bl-text-foreground bl-text-sm/normal lg:bl-text-base/normal">
                  <ul class="bl-flex bl-flex-col bl-gap-4">
                    <li class="bl-flex bl-gap-5"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12"
                        viewBox="0 0 12 12" fill="none"
                        class="bl-text-[#565a65] bl-h-3 bl-w-3 bl-mt-2 bl-min-w-0 bl-shrink-0">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M10.595 4.714 3.095.216A1.5 1.5 0 0 0 .822 1.502v9a1.5 1.5 0 0 0 2.273 1.285l7.5-4.501a1.5 1.5 0 0 0 0-2.572Z"
                          fill="currentColor"></path>
                      </svg><span>{{$t('m.kfzgx')}}</span></li>
                    <li class="bl-flex bl-gap-5"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12"
                        viewBox="0 0 12 12" fill="none"
                        class="bl-text-[#565a65] bl-h-3 bl-w-3 bl-mt-2 bl-min-w-0 bl-shrink-0">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M10.595 4.714 3.095.216A1.5 1.5 0 0 0 .822 1.502v9a1.5 1.5 0 0 0 2.273 1.285l7.5-4.501a1.5 1.5 0 0 0 0-2.572Z"
                          fill="currentColor"></path>
                      </svg><span>{{$t('m.kfzjy')}}</span></li>
                  </ul>
                </div><a href="#" target="_blank" class="bl-block bl-text-right" rel="noreferrer">
                  <div class="bl-text-primary bl-text-sm/normal lg:bl-text-base/normal hover:bl-underline">{{$t('m.more')}}</div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="bl-flex bl-w-full bl-relative bl-justify-end job-position"
          style="transform: translateX(0%) translateZ(0px); opacity: 1;">
          <div class="bl-w-full lg:bl-w-3/5 lg:bl-max-w-[879px] bl-flex bl-flex-col bl-px-4">
            <div class="bl-min-h-[59px] lg:bl-h-[84px] bl-w-full bl-flex">
              <div
                class="bl-w-[49px] lg:bl-w-[164px] bl-h-full bl-bg-primary/20 bl-flex bl-items-center bl-justify-center bl-relative bl-min-w-0 bl-shrink-0">
                <div
                  class="bl-h-2 bl-w-2 lg:bl-h-3 lg:bl-w-3 bl-border-t bl-border-l bl-border-primary bl-absolute bl-top-0 bl-left-0">
                </div>
                <div
                  class="bl-h-2 bl-w-2 lg:bl-h-3 lg:bl-w-3 bl-border-b bl-border-l bl-border-primary bl-absolute bl-bottom-0 bl-left-0">
                </div>
                <div
                  class="bl-h-5 bl-w-5 lg:bl-h-10 lg:bl-w-10 bl-rounded-full bl-bg-primary bl-flex bl-items-center bl-justify-center">
                  <h3
                    class="bl-font-title bl-font-bold bl-text-black bl-text-[10px]/none md:bl-text-[10px]/none lg:bl-text-base/none">
                    02</h3>
                </div>
              </div><a href="#" target="_blank"
                class="bl-grow bl-h-full bl-bg-primary bl-px-5 lg:bl-px-11 bl-flex bl-items-center bl-justify-between bl-duration-200 bl-group hover:bl-bg-secondary"
                rel="noreferrer">
                <div class="bl-text-black bl-text-xl/none bl-py-1 lg:bl-text-[26px]/none">{{$t('m.yjy')}}</div><svg width="36"
                  height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg"
                  class="bl-text-black bl-h-7 bl-w-7 lg:bl-h-9 lg:bl-w-9 bl-duration-200 group-hover:bl-text-white">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="m35.45 16.715-9.275-9.391a2.317 2.317 0 1 0-3.289 3.265l4.158 4.228c.036.033 1.82 1.895 1.82 1.895a1.677 1.677 0 0 1 0 2.692L27.03 21.22l-1.459 1.44-2.813 2.749a2.319 2.319 0 1 0 3.276 3.283l9.404-9.288a1.905 1.905 0 0 0 .013-2.69ZM9 14.78l1.458-1.442 2.785-2.746a2.319 2.319 0 1 0-3.276-3.284L.563 16.596a1.905 1.905 0 0 0-.013 2.69l9.275 9.388a2.316 2.316 0 0 0 3.783-.738 2.315 2.315 0 0 0-.494-2.527l-4.15-4.225-1.82-1.898a1.676 1.676 0 0 1 0-2.692L9 14.78Z"
                    fill="currentColor"></path>
                </svg>
              </a>
            </div>
            <div class="bl-w-full bl-flex">
              <div class="bl-hidden xl:bl-block lg:bl-w-[164px] bl-min-w-0 bl-shrink-0"></div>
              <div class="bl-grow bl-bg-[#101010] bl-border bl-border-[#1b1e21] bl-p-5 lg:bl-p-10 bl-space-y-2">
                <div class="bl-text-foreground bl-text-sm/normal lg:bl-text-base/normal">
                  <ul class="bl-flex bl-flex-col bl-gap-4">
                    <li class="bl-flex bl-gap-5"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12"
                        viewBox="0 0 12 12" fill="none"
                        class="bl-text-[#565a65] bl-h-3 bl-w-3 bl-mt-2 bl-min-w-0 bl-shrink-0">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M10.595 4.714 3.095.216A1.5 1.5 0 0 0 .822 1.502v9a1.5 1.5 0 0 0 2.273 1.285l7.5-4.501a1.5 1.5 0 0 0 0-2.572Z"
                          fill="currentColor"></path>
                      </svg><span>{{$t('m.tdian')}}</span></li>
                    <li class="bl-flex bl-gap-5"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12"
                        viewBox="0 0 12 12" fill="none"
                        class="bl-text-[#565a65] bl-h-3 bl-w-3 bl-mt-2 bl-min-w-0 bl-shrink-0">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M10.595 4.714 3.095.216A1.5 1.5 0 0 0 .822 1.502v9a1.5 1.5 0 0 0 2.273 1.285l7.5-4.501a1.5 1.5 0 0 0 0-2.572Z"
                          fill="currentColor"></path>
                      </svg><span>{{$t('m.lw')}}</span></li>
                  </ul>
                </div><a href="#" target="_blank" class="bl-block bl-text-right" rel="noreferrer">
                  <div class="bl-text-primary bl-text-sm/normal lg:bl-text-base/normal hover:bl-underline">{{$t('m.more')}}</div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div><a href="#" target="_blank" rel="noopener noreferrer"
        class="bl-group/button bl-inline-flex bl-items-center bl-justify-center bl-whitespace-nowrap bl-text-xl/none bl-font-medium bl-ring-offset-background bl-transition-colors focus-visible:bl-outline-none focus-visible:bl-ring-2 focus-visible:bl-ring-ring focus-visible:bl-ring-offset-2 disabled:bl-pointer-events-none disabled:bl-opacity-50 bl-relative bl-appearance-none *:bl-relative *:bl-z-10 group btn-mask bl-bg-secondary bl-text-secondary-foreground hover:bl-text-white bl-h-12 bl-min-w-[196px] md:bl-min-w-[230px] bl-rounded-md bl-px-8 bl-block">
        <div
          class="!bl-absolute !bl-z-0 bl-h-full bl-w-full bl-rounded-xl bl-scale-75 bl-transition-all bl-duration-500 group-hover/button:bl-scale-150 group-disabled:bl-opacity-0 bl-bg-primary bl-translate-y-full group-hover/button:bl-translate-y-0">
        </div><span>{{$t('m.tskq')}}</span>
      </a>
    </section>



    <section id="community" class="bl-container bl-w-full bl-pt-16 bl-pb-24 bl-flex bl-flex-col bl-items-center">
      <div id="sheq"
        class="bl-inline-flex bl-items-center bl-justify-center bl-transition-colors bl-relative bl-h-[55px] bl-min-w-[260px] bl-text-[26px]/none bl-bg-primary/20 bl-text-primary">
        <span>{{$t('m.sheq')}}</span>
        <div class="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-t bl-top-0 bl-left-0"></div>
        <div class="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-b bl-right-0 bl-bottom-0">
        </div>
      </div>
      <div class="bl-mt-8 bl-text-center md:bl-w-[960px]">
        <h3 class="bl-font-title bl-font-bold bl-text-foreground bl-text-[45px]/[1.2] md:bl-text-[90px]/[1.1]">{{$t('m.jrsq')}}</h3>
        <div class="bl-text-foreground bl-text-base/normal md:bl-text-xl/normal bl-mt-5">{{$t('m.hdsq')}}</div>
      </div>
      <div
        class="bl-relative bl-grid bl-border bl-border-divider bl-overflow-hidden before:bl-w-[200%] before:bl-h-[200%] before:bl-absolute before:-bl-left-1/2 before:-bl-top-1/2 before:bl-bg-conic before:bl-animate-rotate after:bl-absolute after:bl-w-full--1 after:bl-h-full--1 after:bl-bg-background after:bl-top-0.5 after:bl-left-0.5 bl-w-full lg:bl-w-[1000px] md:bl-grid-cols-2 bl-mt-40">
        <a class="bl-block bl-border-r bl-border-divider bl-p-7 bl-relative bl-z-10 bl-group hover:bl-border-primary/20 border-b"
          href="#" target="_blank">
          <div
            class="bl-absolute bl-top-0 bl-left-0 bl-w-full bl-h-full bl-bg-gradient-to-b bl-from-primary/50 bl-opacity-0 bl-duration-200 group-hover:bl-opacity-100">
          </div>
          <div class="bl-absolute bl-top-0 bl-left-0 bl-w-full bl-h-full bl-opacity-0 group-hover:bl-opacity-10"
            style="background-image:url('https://www.bitlayer.org/images/bg-grid.svg')"></div>
          <div
            class="bl-absolute bl-h-3 bl-w-3 bl-border-secondary/10 bl-duration-200 group-hover:bl-h-2 group-hover:bl-w-2 group-hover:bl-border-primary bl-top-3 bl-left-3 bl-border-t bl-border-l group-hover:bl-top-4 group-hover:bl-left-4">
          </div>
          <div
            class="bl-absolute bl-h-3 bl-w-3 bl-border-secondary/10 bl-duration-200 group-hover:bl-h-2 group-hover:bl-w-2 group-hover:bl-border-primary bl-top-3 bl-right-3 bl-border-t bl-border-r group-hover:bl-top-4 group-hover:bl-right-4">
          </div>
          <div
            class="bl-absolute bl-h-3 bl-w-3 bl-border-secondary/10 bl-duration-200 group-hover:bl-h-2 group-hover:bl-w-2 group-hover:bl-border-primary bl-bottom-3 bl-left-3 bl-border-b bl-border-l group-hover:bl-bottom-4 group-hover:bl-left-4">
          </div>
          <div
            class="bl-absolute bl-h-3 bl-w-3 bl-border-secondary/10 bl-duration-200 group-hover:bl-h-2 group-hover:bl-w-2 group-hover:bl-border-primary bl-bottom-3 bl-right-3 bl-border-b bl-border-r group-hover:bl-bottom-4 group-hover:bl-right-4">
          </div>
          <div class="bl-relative bl-z-10 bl-h-full bl-w-full">
            <div
              class="bl-w-full bl-relative bl-overflow-hidden bl-duration-200 group-hover:bl-border-primary bl-aspect-video bl-bg-[#111] bl-border-[0.5px] bl-border-[#787d88]">
              <div
                class="bl-absolute bl-w-full bl-h-1 md:bl-h-2 bl-bg-primary bl-top-0 bl-left-0 -bl-translate-y-full bl-duration-200 group-hover:bl-translate-y-0">
              </div><svg width="150" height="146" viewBox="0 0 150 146" fill="none" xmlns="http://www.w3.org/2000/svg"
                class="bl-absolute bl-w-20 bl-h-20 sm:bl-w-[174px] sm:bl-h-[174px] md:bl-w-24 md:bl-h-24 lg:bl-w-[174px] lg:bl-h-[174px] bl-top-0 bl-right-0 bl-translate-x-[15%] -bl-translate-y-[15%] bl-duration-500 group-hover:bl-text-primary group-hover:bl-translate-x-[12%] group-hover:-bl-translate-y-[12%]">
                <path
                  d="M35.691 126.191c3.727 3.097 10.027 5.602 20.342 3.428.018 3.355.044 6.255.064 8.477.016 1.825.028 3.195.028 3.985 0 .95-.328 1.962-1.084 2.652-.747.68-1.947 1.077-3.762.739C21.63 135.608.25 107.635.25 74.652.25 33.424 33.656 0 74.901 0c41.227 0 74.651 33.425 74.651 74.651 0 32.935-21.361 60.926-51.222 70.721-1.864.361-3.071-.033-3.813-.711-.75-.685-1.064-1.696-1.064-2.636 0-.802.01-2.215.023-4.11.008-.976.015-2.08.023-3.294.023-3.569.047-8.087.047-13.097 0-6.744-2.235-11.295-4.77-13.69 8.192-.947 16.582-3.021 22.965-8.367 6.517-5.46 10.909-14.3 10.909-28.727 0-8.178-2.886-14.872-7.64-20.135.814-2.112 3.171-9.68-.797-19.847l-.037-.096-.097-.031-.067.205.066-.205h-.001l-.002-.001-.005-.002-.017-.005-.062-.016a3.603 3.603 0 0 0-.238-.05 7.077 7.077 0 0 0-.932-.099c-.83-.038-2.076.015-3.766.373-3.366.713-8.488 2.633-15.576 7.445-5.98-1.654-12.33-2.471-18.676-2.508H74.8c-6.347.037-12.696.854-18.677 2.508-7.134-4.813-12.28-6.733-15.657-7.445-1.696-.358-2.945-.41-3.776-.373-.415.02-.725.06-.934.099a3.655 3.655 0 0 0-.3.066l-.017.005-.005.002h-.002l.065.206-.066-.205-.098.031-.037.096c-3.964 10.16-1.615 17.726-.707 19.85-4.783 5.264-7.668 11.956-7.668 20.132 0 14.39 4.386 23.238 10.893 28.718 6.374 5.368 14.753 7.475 22.931 8.438-2.023 1.939-3.788 5.148-4.445 9.705-2.142.946-5.827 2.187-9.788 1.832-4.014-.359-8.332-2.355-11.633-8.017l-.001-.001-.001-.002-.01-.016a10.44 10.44 0 0 0-.177-.297 15.093 15.093 0 0 0-.558-.833 18.797 18.797 0 0 0-2.205-2.558c-1.948-1.883-4.86-3.845-8.687-4.112H23.227l-.002.215.002-.215h-.006l-.017-.001-.062.001a9.653 9.653 0 0 0-.977.059c-.583.064-1.322.201-1.852.502-.267.152-.502.357-.624.637-.125.285-.118.614.034.979.295.707 1.159 1.601 2.856 2.763l.015.01.015.007.002.001.009.004a8.093 8.093 0 0 1 .782.475c.504.344 1.215.894 2.034 1.712 1.634 1.636 3.699 4.346 5.39 8.635l.002.003.01.034.043.125c.038.109.097.267.18.465.163.397.42.956.793 1.612a16.757 16.757 0 0 0 3.837 4.571Z"
                  fill="currentColor" fill-opacity="0.5"></path>
                <path
                  d="M23.227 103.597H23.24c3.827.267 6.739 2.229 8.687 4.112a18.797 18.797 0 0 1 2.205 2.558 15.093 15.093 0 0 1 .7 1.067l.035.063.01.016v.002l.002.001c3.301 5.662 7.62 7.658 11.633 8.017 3.961.355 7.646-.886 9.788-1.832.657-4.557 2.422-7.766 4.445-9.705-8.178-.963-16.556-3.07-22.931-8.438C31.307 93.978 26.92 85.13 26.92 70.74c0-8.176 2.885-14.868 7.668-20.132-.908-2.124-3.257-9.69.707-19.85l.037-.096.098-.031.066.205-.065-.205.002-.001.005-.002.017-.005.062-.016c.054-.014.133-.032.238-.05.209-.038.519-.08.934-.099.83-.038 2.08.015 3.776.373 3.377.713 8.523 2.632 15.657 7.445 5.981-1.654 12.33-2.471 18.677-2.508h.003c6.347.037 12.696.854 18.676 2.508 7.088-4.813 12.21-6.733 15.576-7.445 1.69-.358 2.936-.41 3.766-.373.414.02.724.06.932.099.105.018.184.036.238.05l.062.016.017.005.005.002h.003l-.066.206.067-.205.097.031.037.096c3.968 10.167 1.611 17.735.797 19.847 4.754 5.263 7.64 11.957 7.64 20.135 0 14.427-4.392 23.268-10.909 28.727-6.383 5.346-14.773 7.42-22.965 8.367 2.535 2.395 4.77 6.946 4.77 13.69a1999.322 1999.322 0 0 1-.07 16.391c-.013 1.895-.023 3.308-.023 4.11 0 .94.314 1.951 1.064 2.636.742.678 1.95 1.072 3.813.711 29.861-9.795 51.222-37.786 51.222-70.72C149.552 33.424 116.128 0 74.902 0 33.655 0 .25 33.425.25 74.651c0 32.984 21.38 60.957 51.029 70.821 1.815.338 3.015-.059 3.762-.739.756-.69 1.084-1.702 1.084-2.652 0-.79-.012-2.16-.028-3.985-.02-2.222-.046-5.122-.064-8.477-10.315 2.174-16.615-.331-20.342-3.428a16.757 16.757 0 0 1-3.837-4.571 14.31 14.31 0 0 1-.794-1.612 9.387 9.387 0 0 1-.179-.465c-.02-.054-.033-.097-.043-.125l-.01-.034-.002-.003c-1.691-4.289-3.756-6.999-5.39-8.635a14.363 14.363 0 0 0-2.034-1.712 8.093 8.093 0 0 0-.744-.455.398.398 0 0 1-.038-.02l-.009-.004-.002-.001-.015-.007-.015-.01c-1.697-1.162-2.56-2.056-2.856-2.763-.152-.365-.159-.694-.034-.979.122-.28.357-.485.624-.637.53-.301 1.27-.438 1.853-.502a9.653 9.653 0 0 1 .976-.059l.063-.001.016.001h.007Zm0 0-.002.215.002-.215Z"
                  stroke="currentColor" stroke-width="0.5"></path>
              </svg>
              <div
                class="bl-flex bl-flex-col bl-justify-end bl-h-full bl-p-3 sm:bl-p-9 md:bl-p-3 sm:bl-pr-20 lg:bl-p-9">
                <h3
                  class="bl-font-title bl-font-bold bl-text-foreground bl-text-xl/normal md:bl-text-xl/none group-hover:bl-text-white">
                  {{$t('m.jrdw')}}</h3>
                <div class="bl-text-foreground bl-mt-2 sm:bl-mt-5 bl-text-xs/normal md:bl-text-sm/normal">{{$t('m.wmgj')}}</div>
              </div>
            </div>
          </div>
        </a><a
          class="bl-block bl-border-r bl-border-divider bl-p-7 bl-relative bl-z-10 bl-group hover:bl-border-primary/20 border-b"
          href="#" target="_blank">
          <div
            class="bl-absolute bl-top-0 bl-left-0 bl-w-full bl-h-full bl-bg-gradient-to-b bl-from-primary/50 bl-opacity-0 bl-duration-200 group-hover:bl-opacity-100">
          </div>
          <div class="bl-absolute bl-top-0 bl-left-0 bl-w-full bl-h-full bl-opacity-0 group-hover:bl-opacity-10"
            style="background-image:url('https://www.bitlayer.org/images/bg-grid.svg')"></div>
          <div
            class="bl-absolute bl-h-3 bl-w-3 bl-border-secondary/10 bl-duration-200 group-hover:bl-h-2 group-hover:bl-w-2 group-hover:bl-border-primary bl-top-3 bl-left-3 bl-border-t bl-border-l group-hover:bl-top-4 group-hover:bl-left-4">
          </div>
          <div
            class="bl-absolute bl-h-3 bl-w-3 bl-border-secondary/10 bl-duration-200 group-hover:bl-h-2 group-hover:bl-w-2 group-hover:bl-border-primary bl-top-3 bl-right-3 bl-border-t bl-border-r group-hover:bl-top-4 group-hover:bl-right-4">
          </div>
          <div
            class="bl-absolute bl-h-3 bl-w-3 bl-border-secondary/10 bl-duration-200 group-hover:bl-h-2 group-hover:bl-w-2 group-hover:bl-border-primary bl-bottom-3 bl-left-3 bl-border-b bl-border-l group-hover:bl-bottom-4 group-hover:bl-left-4">
          </div>
          <div
            class="bl-absolute bl-h-3 bl-w-3 bl-border-secondary/10 bl-duration-200 group-hover:bl-h-2 group-hover:bl-w-2 group-hover:bl-border-primary bl-bottom-3 bl-right-3 bl-border-b bl-border-r group-hover:bl-bottom-4 group-hover:bl-right-4">
          </div>
          <div class="bl-relative bl-z-10 bl-h-full bl-w-full">
            <div
              class="bl-w-full bl-relative bl-overflow-hidden bl-duration-200 group-hover:bl-border-primary bl-aspect-video bl-bg-[#111] bl-border-[0.5px] bl-border-[#787d88]">
              <div
                class="bl-absolute bl-w-full bl-h-1 md:bl-h-2 bl-bg-primary bl-top-0 bl-left-0 -bl-translate-y-full bl-duration-200 group-hover:bl-translate-y-0">
              </div><svg width="165" height="127" viewBox="0 0 165 127" fill="none" xmlns="http://www.w3.org/2000/svg"
                class="bl-absolute bl-w-20 bl-h-20 sm:bl-w-[174px] sm:bl-h-[174px] md:bl-w-24 md:bl-h-24 lg:bl-w-[174px] lg:bl-h-[174px] bl-top-0 bl-right-0 bl-translate-x-[15%] -bl-translate-y-[15%] bl-duration-500 group-hover:bl-text-primary group-hover:bl-translate-x-[12%] group-hover:-bl-translate-y-[12%]">
                <path
                  d="M139.594 11.23h-.001l.009.002c.048.02.087.056.11.1l.005.011.007.01c18.609 27.371 27.797 58.24 24.362 93.781a.316.316 0 0 1-.133.233c-14.093 10.349-27.743 16.632-41.204 20.797a.319.319 0 0 1-.352-.119l-.001-.001a109.814 109.814 0 0 1-8.406-13.678.315.315 0 0 1 .059-.374.315.315 0 0 1 .111-.073 84.85 84.85 0 0 0 12.908-6.143l.003-.002a.727.727 0 0 0 .355-.591.737.737 0 0 0-.292-.626l-.001-.001a66.209 66.209 0 0 1-2.556-2.004.712.712 0 0 0-.756-.092c-26.63 12.303-55.8 12.303-82.75-.001l-.002-.001a.708.708 0 0 0-.748.102 69.926 69.926 0 0 1-2.548 1.997l-.001.001a.731.731 0 0 0-.182.969.74.74 0 0 0 .254.249l.002.001c4.119 2.362 8.401 4.442 12.9 6.148l.003.001a.32.32 0 0 1 .173.442 97.642 97.642 0 0 1-8.41 13.68.33.33 0 0 1-.354.116C28.76 122 15.112 115.718 1.02 105.37a.363.363 0 0 1-.135-.243c-2.87-30.742 2.98-61.865 24.343-93.78l.004-.007a.275.275 0 0 1 .126-.11l.005-.002A135.482 135.482 0 0 1 58.898.828a.323.323 0 0 1 .328.155c1.454 2.575 3.115 5.873 4.235 8.567l.062.148.159-.024c12.4-1.896 24.998-1.896 37.66 0l.158.023.061-.146c1.123-2.64 2.726-5.998 4.173-8.57l.001-.001a.302.302 0 0 1 .321-.152h.004a135.868 135.868 0 0 1 33.534 10.401Zm-98.886 91.376a.507.507 0 0 1 .277.04l10.12 9.815a.525.525 0 0 0-.286-.727c-4.488-1.702-8.76-3.778-12.871-6.134a.533.533 0 0 1-.052-.879 69.099 69.099 0 0 0 2.556-2.003.517.517 0 0 1 .256-.112Zm-.604-32.834c0 9.257 6.77 16.835 15.034 16.835l14.828-16.835.205.003v-.001c.131-9.194-6.575-16.838-15.033-16.838-8.396 0-15.034 7.58-15.034 16.836Zm54.826 0c0 9.257 6.77 16.835 15.034 16.835l14.829-16.835.205.003v-.002c.13-9.194-6.576-16.837-15.034-16.837-8.395 0-15.034 7.58-15.034 16.836Z"
                  fill="currentColor" fill-opacity="0.5"></path>
                <path
                  d="M127.358 104.834a.723.723 0 0 1 .068.349.727.727 0 0 1-.355.591l-.003.002a84.85 84.85 0 0 1-12.908 6.143.32.32 0 0 0-.17.447 109.814 109.814 0 0 0 8.406 13.678l.001.001a.319.319 0 0 0 .352.119c13.461-4.165 27.111-10.448 41.204-20.797a.316.316 0 0 0 .133-.233c3.435-35.54-5.753-66.41-24.362-93.78l-.007-.01-.005-.012a.214.214 0 0 0-.11-.1l-.009-.003h.001A135.868 135.868 0 0 0 106.06.828h-.004a.302.302 0 0 0-.185.023m21.487 103.983-.186.086m.186-.086a.737.737 0 0 0-.224-.277l-.001-.001a66.209 66.209 0 0 1-2.556-2.004.712.712 0 0 0-.756-.092c-26.63 12.303-55.8 12.303-82.75-.001l-.002-.001a.708.708 0 0 0-.748.102 69.926 69.926 0 0 1-2.548 1.997l-.001.001a.731.731 0 0 0-.182.969.74.74 0 0 0 .254.249l.002.001c4.119 2.362 8.401 4.442 12.9 6.148l.003.001a.32.32 0 0 1 .173.442 97.642 97.642 0 0 1-8.41 13.68.33.33 0 0 1-.354.116C28.76 122 15.112 115.718 1.02 105.37a.363.363 0 0 1-.135-.243c-2.87-30.742 2.98-61.865 24.343-93.78l.004-.007a.275.275 0 0 1 .126-.11l.005-.002A135.482 135.482 0 0 1 58.898.828a.323.323 0 0 1 .328.155c1.454 2.575 3.115 5.873 4.235 8.567l.062.148.159-.024c12.4-1.896 24.998-1.896 37.66 0l.158.023.061-.146c1.123-2.64 2.726-5.998 4.173-8.57l.001-.001a.298.298 0 0 1 .136-.129m0 0-.088-.185m-65.075 101.94a.507.507 0 0 1 .277.04l10.12 9.815a.525.525 0 0 0-.286-.727c-4.488-1.702-8.76-3.778-12.871-6.134a.533.533 0 0 1-.052-.879 69.099 69.099 0 0 0 2.556-2.003.517.517 0 0 1 .256-.112Zm-.604-32.834c0 9.257 6.77 16.835 15.034 16.835l14.828-16.835.205.003v-.001c.131-9.194-6.575-16.838-15.033-16.838-8.396 0-15.034 7.58-15.034 16.836Zm54.826 0c0 9.257 6.77 16.835 15.034 16.835l14.829-16.835.205.003v-.002c.13-9.194-6.576-16.837-15.034-16.837-8.395 0-15.034 7.58-15.034 16.836Z"
                  stroke="currentColor" stroke-width="0.5"></path>
              </svg>
              <div
                class="bl-flex bl-flex-col bl-justify-end bl-h-full bl-p-3 sm:bl-p-9 md:bl-p-3 sm:bl-pr-20 lg:bl-p-9">
                <h3
                  class="bl-font-title bl-font-bold bl-text-foreground bl-text-xl/normal md:bl-text-xl/none group-hover:bl-text-white">
                  {{$t('m.jrdskd')}}</h3>
                <div class="bl-text-foreground bl-mt-2 sm:bl-mt-5 bl-text-xs/normal md:bl-text-sm/normal">{{$t('m.cyjl')}}</div>
              </div>
            </div>
          </div>
        </a><a
          class="bl-block bl-border-r bl-border-divider bl-p-7 bl-relative bl-z-10 bl-group hover:bl-border-primary/20"
          href="#" target="_blank">
          <div
            class="bl-absolute bl-top-0 bl-left-0 bl-w-full bl-h-full bl-bg-gradient-to-b bl-from-primary/50 bl-opacity-0 bl-duration-200 group-hover:bl-opacity-100">
          </div>
          <div class="bl-absolute bl-top-0 bl-left-0 bl-w-full bl-h-full bl-opacity-0 group-hover:bl-opacity-10"
            style="background-image:url('https://www.bitlayer.org/images/bg-grid.svg')"></div>
          <div
            class="bl-absolute bl-h-3 bl-w-3 bl-border-secondary/10 bl-duration-200 group-hover:bl-h-2 group-hover:bl-w-2 group-hover:bl-border-primary bl-top-3 bl-left-3 bl-border-t bl-border-l group-hover:bl-top-4 group-hover:bl-left-4">
          </div>
          <div
            class="bl-absolute bl-h-3 bl-w-3 bl-border-secondary/10 bl-duration-200 group-hover:bl-h-2 group-hover:bl-w-2 group-hover:bl-border-primary bl-top-3 bl-right-3 bl-border-t bl-border-r group-hover:bl-top-4 group-hover:bl-right-4">
          </div>
          <div
            class="bl-absolute bl-h-3 bl-w-3 bl-border-secondary/10 bl-duration-200 group-hover:bl-h-2 group-hover:bl-w-2 group-hover:bl-border-primary bl-bottom-3 bl-left-3 bl-border-b bl-border-l group-hover:bl-bottom-4 group-hover:bl-left-4">
          </div>
          <div
            class="bl-absolute bl-h-3 bl-w-3 bl-border-secondary/10 bl-duration-200 group-hover:bl-h-2 group-hover:bl-w-2 group-hover:bl-border-primary bl-bottom-3 bl-right-3 bl-border-b bl-border-r group-hover:bl-bottom-4 group-hover:bl-right-4">
          </div>
          <div class="bl-relative bl-z-10 bl-h-full bl-w-full">
            <div
              class="bl-w-full bl-relative bl-overflow-hidden bl-duration-200 group-hover:bl-border-primary bl-aspect-video bl-bg-[#111] bl-border-[0.5px] bl-border-[#787d88]">
              <div
                class="bl-absolute bl-w-full bl-h-1 md:bl-h-2 bl-bg-primary bl-top-0 bl-left-0 -bl-translate-y-full bl-duration-200 group-hover:bl-translate-y-0">
              </div><svg width="139" height="125" viewBox="0 0 139 125" fill="none" xmlns="http://www.w3.org/2000/svg"
                class="bl-absolute bl-w-20 bl-h-20 sm:bl-w-[174px] sm:bl-h-[174px] md:bl-w-24 md:bl-h-24 lg:bl-w-[174px] lg:bl-h-[174px] bl-top-0 bl-right-0 bl-translate-x-[15%] -bl-translate-y-[15%] bl-duration-500 group-hover:bl-text-primary group-hover:bl-translate-x-[12%] group-hover:-bl-translate-y-[12%]">
                <path
                  d="M62.426 80.579 24.374 124H3.757l49.09-56.041.107-.122-.098-.127L1 .006h43.104l30.043 39.672.146.192.16-.181L109.21 0h20.605L83.94 52.372l-.106.12.097.128L138 124H95.972L62.73 80.589l-.146-.191-.159.18Zm39.126 31.11.059.078h12.199l-.238-.316-75.575-99.799-.059-.078H24.88l.242.316 76.43 99.799Z"
                  fill="currentColor" fill-opacity="0.5" stroke="currentColor" stroke-width="0.5"></path>
              </svg>
              <div
                class="bl-flex bl-flex-col bl-justify-end bl-h-full bl-p-3 sm:bl-p-9 md:bl-p-3 sm:bl-pr-20 lg:bl-p-9">
                <h3
                  class="bl-font-title bl-font-bold bl-text-foreground bl-text-xl/normal md:bl-text-xl/none group-hover:bl-text-white">
                  {{$t('m.gztt')}}</h3>
                <div class="bl-text-foreground bl-mt-2 sm:bl-mt-5 bl-text-xs/normal md:bl-text-sm/normal">{{$t('m.zxzxjz')}}</div>
              </div>
            </div>
          </div>
        </a><a
          class="bl-block bl-border-r bl-border-divider bl-p-7 bl-relative bl-z-10 bl-group hover:bl-border-primary/20"
          href="#" target="_blank">
          <div
            class="bl-absolute bl-top-0 bl-left-0 bl-w-full bl-h-full bl-bg-gradient-to-b bl-from-primary/50 bl-opacity-0 bl-duration-200 group-hover:bl-opacity-100">
          </div>
          <div class="bl-absolute bl-top-0 bl-left-0 bl-w-full bl-h-full bl-opacity-0 group-hover:bl-opacity-10"
            style="background-image:url('https://www.bitlayer.org/images/bg-grid.svg')"></div>
          <div
            class="bl-absolute bl-h-3 bl-w-3 bl-border-secondary/10 bl-duration-200 group-hover:bl-h-2 group-hover:bl-w-2 group-hover:bl-border-primary bl-top-3 bl-left-3 bl-border-t bl-border-l group-hover:bl-top-4 group-hover:bl-left-4">
          </div>
          <div
            class="bl-absolute bl-h-3 bl-w-3 bl-border-secondary/10 bl-duration-200 group-hover:bl-h-2 group-hover:bl-w-2 group-hover:bl-border-primary bl-top-3 bl-right-3 bl-border-t bl-border-r group-hover:bl-top-4 group-hover:bl-right-4">
          </div>
          <div
            class="bl-absolute bl-h-3 bl-w-3 bl-border-secondary/10 bl-duration-200 group-hover:bl-h-2 group-hover:bl-w-2 group-hover:bl-border-primary bl-bottom-3 bl-left-3 bl-border-b bl-border-l group-hover:bl-bottom-4 group-hover:bl-left-4">
          </div>
          <div
            class="bl-absolute bl-h-3 bl-w-3 bl-border-secondary/10 bl-duration-200 group-hover:bl-h-2 group-hover:bl-w-2 group-hover:bl-border-primary bl-bottom-3 bl-right-3 bl-border-b bl-border-r group-hover:bl-bottom-4 group-hover:bl-right-4">
          </div>
          <div class="bl-relative bl-z-10 bl-h-full bl-w-full">
            <div
              class="bl-w-full bl-relative bl-overflow-hidden bl-duration-200 group-hover:bl-border-primary bl-aspect-video bl-bg-[#111] bl-border-[0.5px] bl-border-[#787d88]">
              <div
                class="bl-absolute bl-w-full bl-h-1 md:bl-h-2 bl-bg-primary bl-top-0 bl-left-0 -bl-translate-y-full bl-duration-200 group-hover:bl-translate-y-0">
              </div><svg width="130" height="129" viewBox="0 0 130 129" fill="none" xmlns="http://www.w3.org/2000/svg"
                class="bl-absolute bl-w-20 bl-h-20 sm:bl-w-[174px] sm:bl-h-[174px] md:bl-w-24 md:bl-h-24 lg:bl-w-[174px] lg:bl-h-[174px] bl-top-0 bl-right-0 bl-translate-x-[15%] -bl-translate-y-[15%] bl-duration-500 group-hover:bl-text-primary group-hover:bl-translate-x-[12%] group-hover:-bl-translate-y-[12%]">
                <path
                  d="M110.278 110.233h.429v-.428l.005-33.88c0-8.324-.894-15.783-4.287-21.165-3.422-5.428-9.33-8.666-19.14-8.666-8.893 0-15.061 4.563-17.983 9.232v-7.674H50.043V110.233h20.013V79.261c0-4.019.385-7.882 1.958-10.733.781-1.416 1.853-2.578 3.322-3.39 1.47-.814 3.363-1.29 5.805-1.29 2.39 0 4.153.559 5.463 1.474 1.313.916 2.208 2.214 2.818 3.756 1.23 3.108 1.272 7.114 1.272 10.711v30.444h19.584Zm-71.836 0h.428V47.652H18.804V110.233h19.639ZM17.29 28.505c0 6.374 5.144 11.558 11.547 11.558 6.376 0 11.553-5.183 11.553-11.559 0-6.37-5.172-11.547-11.553-11.547-6.398 0-11.547 5.178-11.547 11.547ZM9.617 0h110.228C124.892 0 129 3.992 129 8.89v110.72c0 4.903-4.108 8.89-9.155 8.89H9.617c-5.05 0-9.117-3.984-9.117-8.89V8.89C.5 3.99 4.567 0 9.617 0Z"
                  fill="currentColor" fill-opacity="0.5" stroke="currentColor"></path>
              </svg>
              <div
                class="bl-flex bl-flex-col bl-justify-end bl-h-full bl-p-3 sm:bl-p-9 md:bl-p-3 sm:bl-pr-20 lg:bl-p-9">
                <h3
                  class="bl-font-title bl-font-bold bl-text-foreground bl-text-xl/normal md:bl-text-xl/none group-hover:bl-text-white">
                  {{$t('m.lxwm')}}</h3>
                <div class="bl-text-foreground bl-mt-2 sm:bl-mt-5 bl-text-xs/normal md:bl-text-sm/normal">{{$t('m.ywmlx')}}</div>
              </div>
            </div>
          </div>
        </a><a
          class="bl-block bl-border-r bl-border-divider bl-p-7 bl-relative bl-z-10 bl-group hover:bl-border-primary/20"
          href="#" target="_blank">
          <div
            class="bl-absolute bl-top-0 bl-left-0 bl-w-full bl-h-full bl-bg-gradient-to-b bl-from-primary/50 bl-opacity-0 bl-duration-200 group-hover:bl-opacity-100">
          </div>
          <div class="bl-absolute bl-top-0 bl-left-0 bl-w-full bl-h-full bl-opacity-0 group-hover:bl-opacity-10"
            style="background-image:url('https://www.bitlayer.org/images/bg-grid.svg')"></div>
          <div
            class="bl-absolute bl-h-3 bl-w-3 bl-border-secondary/10 bl-duration-200 group-hover:bl-h-2 group-hover:bl-w-2 group-hover:bl-border-primary bl-top-3 bl-left-3 bl-border-t bl-border-l group-hover:bl-top-4 group-hover:bl-left-4">
          </div>
          <div
            class="bl-absolute bl-h-3 bl-w-3 bl-border-secondary/10 bl-duration-200 group-hover:bl-h-2 group-hover:bl-w-2 group-hover:bl-border-primary bl-top-3 bl-right-3 bl-border-t bl-border-r group-hover:bl-top-4 group-hover:bl-right-4">
          </div>
          <div
            class="bl-absolute bl-h-3 bl-w-3 bl-border-secondary/10 bl-duration-200 group-hover:bl-h-2 group-hover:bl-w-2 group-hover:bl-border-primary bl-bottom-3 bl-left-3 bl-border-b bl-border-l group-hover:bl-bottom-4 group-hover:bl-left-4">
          </div>
          <div
            class="bl-absolute bl-h-3 bl-w-3 bl-border-secondary/10 bl-duration-200 group-hover:bl-h-2 group-hover:bl-w-2 group-hover:bl-border-primary bl-bottom-3 bl-right-3 bl-border-b bl-border-r group-hover:bl-bottom-4 group-hover:bl-right-4">
          </div>
          <div class="bl-relative bl-z-10 bl-h-full bl-w-full">
            <div
              class="bl-w-full bl-relative bl-overflow-hidden bl-duration-200 group-hover:bl-border-primary bl-aspect-video bl-bg-[#111] bl-border-[0.5px] bl-border-[#787d88]">
              <div
                class="bl-absolute bl-w-full bl-h-1 md:bl-h-2 bl-bg-primary bl-top-0 bl-left-0 -bl-translate-y-full bl-duration-200 group-hover:bl-translate-y-0">
              </div><svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23"
                fill="currentColor"
                class="bl-absolute bl-w-20 bl-h-20 sm:bl-w-[174px] sm:bl-h-[174px] md:bl-w-24 md:bl-h-24 lg:bl-w-[174px] lg:bl-h-[174px] bl-top-0 bl-right-0 bl-translate-x-[15%] -bl-translate-y-[15%] bl-duration-500 group-hover:bl-text-primary group-hover:bl-translate-x-[12%] group-hover:-bl-translate-y-[12%]">
                <path
                  d="M11.4463 3.13056e-05C8.40565 0.0142213 5.49435 1.23208 3.34928 3.38721C1.2042 5.54234 -3.31095e-05 8.45931 6.8275e-10 11.5C6.82756e-10 14.55 1.2116 17.4751 3.36827 19.6317C5.52494 21.7884 8.45001 23 11.5 23C14.55 23 17.4751 21.7884 19.6317 19.6317C21.7884 17.4751 23 14.55 23 11.5C23 8.45003 21.7884 5.52496 19.6317 3.3683C17.4751 1.21163 14.55 3.13056e-05 11.5 3.13056e-05C11.4821 -1.04352e-05 11.4642 -1.04352e-05 11.4463 3.13056e-05ZM16.2016 6.92302C16.2974 6.92111 16.5092 6.94506 16.6472 7.05719C16.7389 7.13682 16.7974 7.24797 16.8111 7.36865C16.8264 7.45777 16.8456 7.6619 16.8302 7.82098C16.6577 9.63989 15.9083 14.0521 15.5269 16.0885C15.3659 16.951 15.0487 17.2395 14.7411 17.2673C14.0741 17.3295 13.5671 16.8264 12.9203 16.4028C11.9083 15.7387 11.3361 15.3257 10.3538 14.6778C9.21821 13.9303 9.95421 13.5183 10.6011 12.8474C10.7707 12.6711 13.7128 9.99448 13.7703 9.75202C13.777 9.72135 13.7837 9.60827 13.7166 9.54885C13.6495 9.48943 13.5499 9.50956 13.478 9.52585C13.3764 9.54885 11.7597 10.6184 8.62788 12.7315C8.16788 13.0477 7.75292 13.2011 7.38013 13.1915C6.96996 13.1838 6.18029 12.9605 5.59283 12.7698C4.87217 12.535 4.30004 12.4114 4.34987 12.0137C4.37575 11.8067 4.66133 11.5949 5.20567 11.3783C8.55792 9.91781 10.7928 8.95469 11.9121 8.4899C15.1052 7.16165 15.7694 6.93069 16.2016 6.92302Z"
                  fill="currentColor" fill-opacity="0.5" stroke-width="0.1" stroke="currentColor"></path>
              </svg>
              <div
                class="bl-flex bl-flex-col bl-justify-end bl-h-full bl-p-3 sm:bl-p-9 md:bl-p-3 sm:bl-pr-20 lg:bl-p-9">
                <h3
                  class="bl-font-title bl-font-bold bl-text-foreground bl-text-xl/normal md:bl-text-xl/none group-hover:bl-text-white">
                  {{$t('m.jrdbq')}}</h3>
                <div class="bl-text-foreground bl-mt-2 sm:bl-mt-5 bl-text-xs/normal md:bl-text-sm/normal">{{$t('m.bzzx')}}</div>
              </div>
            </div>
          </div>
        </a><a
          class="bl-block bl-border-r bl-border-divider bl-p-7 bl-relative bl-z-10 bl-group hover:bl-border-primary/20"
          href="#" target="_blank">
          <div
            class="bl-absolute bl-top-0 bl-left-0 bl-w-full bl-h-full bl-bg-gradient-to-b bl-from-primary/50 bl-opacity-0 bl-duration-200 group-hover:bl-opacity-100">
          </div>
          <div class="bl-absolute bl-top-0 bl-left-0 bl-w-full bl-h-full bl-opacity-0 group-hover:bl-opacity-10"
            style="background-image:url('https://www.bitlayer.org/images/bg-grid.svg')"></div>
          <div
            class="bl-absolute bl-h-3 bl-w-3 bl-border-secondary/10 bl-duration-200 group-hover:bl-h-2 group-hover:bl-w-2 group-hover:bl-border-primary bl-top-3 bl-left-3 bl-border-t bl-border-l group-hover:bl-top-4 group-hover:bl-left-4">
          </div>
          <div
            class="bl-absolute bl-h-3 bl-w-3 bl-border-secondary/10 bl-duration-200 group-hover:bl-h-2 group-hover:bl-w-2 group-hover:bl-border-primary bl-top-3 bl-right-3 bl-border-t bl-border-r group-hover:bl-top-4 group-hover:bl-right-4">
          </div>
          <div
            class="bl-absolute bl-h-3 bl-w-3 bl-border-secondary/10 bl-duration-200 group-hover:bl-h-2 group-hover:bl-w-2 group-hover:bl-border-primary bl-bottom-3 bl-left-3 bl-border-b bl-border-l group-hover:bl-bottom-4 group-hover:bl-left-4">
          </div>
          <div
            class="bl-absolute bl-h-3 bl-w-3 bl-border-secondary/10 bl-duration-200 group-hover:bl-h-2 group-hover:bl-w-2 group-hover:bl-border-primary bl-bottom-3 bl-right-3 bl-border-b bl-border-r group-hover:bl-bottom-4 group-hover:bl-right-4">
          </div>
          <div class="bl-relative bl-z-10 bl-h-full bl-w-full">
            <div
              class="bl-w-full bl-relative bl-overflow-hidden bl-duration-200 group-hover:bl-border-primary bl-aspect-video bl-bg-[#111] bl-border-[0.5px] bl-border-[#787d88]">
              <div
                class="bl-absolute bl-w-full bl-h-1 md:bl-h-2 bl-bg-primary bl-top-0 bl-left-0 -bl-translate-y-full bl-duration-200 group-hover:bl-translate-y-0">
              </div><svg width="149" height="92" viewBox="0 0 149 92" fill="none" xmlns="http://www.w3.org/2000/svg"
                class="bl-absolute bl-w-20 bl-h-20 sm:bl-w-[174px] sm:bl-h-[174px] md:bl-w-24 md:bl-h-24 lg:bl-w-[174px] lg:bl-h-[174px] bl-top-0 bl-right-0 bl-translate-x-[15%] -bl-translate-y-[15%] bl-duration-500 group-hover:bl-text-primary group-hover:bl-translate-x-[12%] group-hover:-bl-translate-y-[12%] right-4">
                <path
                  d="M.5 45.958v-.002c-.033-11.822 4.345-23.17 12.164-31.55 7.818-8.38 18.434-13.11 29.517-13.163 11.082.052 21.699 4.782 29.516 13.162 7.819 8.382 12.197 19.729 12.164 31.55v.003c.033 11.822-4.345 23.17-12.164 31.55-7.817 8.38-18.434 13.111-29.516 13.163-11.083-.052-21.7-4.783-29.517-13.162C4.845 69.127.467 57.78.5 45.959Zm129.569-.001c0 11.672-2.341 22.232-6.108 29.856-3.782 7.654-8.922 12.208-14.462 12.208-5.541 0-10.696-4.554-14.493-12.209-3.783-7.624-6.139-18.184-6.139-29.855s2.356-22.231 6.139-29.855c3.797-7.655 8.952-12.209 14.493-12.209 5.54 0 10.68 4.553 14.462 12.208 3.767 7.624 6.108 18.184 6.108 29.856Zm18.931 0c0 10.482-.824 19.976-2.156 26.838-.665 3.434-1.455 6.185-2.319 8.066-.433.941-.873 1.638-1.305 2.093-.433.455-.817.627-1.152.627-.334 0-.718-.172-1.151-.627-.432-.455-.872-1.152-1.305-2.093-.864-1.88-1.654-4.632-2.32-8.066-1.331-6.862-2.155-16.356-2.155-26.838s.824-19.976 2.155-26.838c.666-3.434 1.456-6.185 2.32-8.065.433-.942.873-1.64 1.305-2.094.433-.455.817-.627 1.151-.627.335 0 .719.172 1.152.627.432.455.872 1.152 1.305 2.094.864 1.88 1.654 4.63 2.319 8.065C148.176 25.981 149 35.475 149 45.957Z"
                  fill="currentColor" fill-opacity="0.5" stroke="currentColor"></path>
              </svg>
              <div
                class="bl-flex bl-flex-col bl-justify-end bl-h-full bl-p-3 sm:bl-p-9 md:bl-p-3 sm:bl-pr-20 lg:bl-p-9">
                <h3
                  class="bl-font-title bl-font-bold bl-text-foreground bl-text-xl/normal md:bl-text-xl/none group-hover:bl-text-white">
                  {{$t('m.gzmdgx')}}</h3>
                <div class="bl-text-foreground bl-mt-2 sm:bl-mt-5 bl-text-xs/normal md:bl-text-sm/normal">{{$t('m.gdzn')}}
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </section>

    <footer class="">
      <div class="bl-border-y bl-border-primary md:bl-grid md:bl-grid-cols-2 bl-w-full">
        <div class="bl-w-full bl-p-10 bl-bg-primary bl-flex bl-flex-col md:bl-flex-row bl-gap-8 bl-flex-wrap">
          <div class="bl-flex bl-flex-col bl-gap-2">
            <div class="bl-text-black bl-text-base/normal md:bl-text-xl/normal">{{$t('m.cy')}}</div>
            <ul class="bl-flex bl-flex-col bl-gap-1">
              <li>
                <div
                  class="bl-font-body bl-text-lg md:bl-text-[15px] bl-text-primary-foreground bl-duration-200 hover:bl-text-white">
                  <a href="">{{$t('m.thwj')}}</a>
                </div>
              </li>
            </ul>
          </div>
          <div class="bl-flex bl-flex-col bl-gap-2">
            <div class="bl-text-black bl-text-base/normal md:bl-text-xl/normal">{{$t('m.gj2')}}</div>
            <ul class="bl-flex bl-flex-col bl-gap-1">
              <li>
                <div
                  class="bl-font-body bl-text-lg md:bl-text-[15px] bl-text-primary-foreground bl-duration-200 hover:bl-text-white">
                  <a href="">{{$t('m.gj3')}}</a>
                </div>
              </li>
              <li>
                <div
                  class="bl-font-body bl-text-lg md:bl-text-[15px] bl-text-primary-foreground bl-duration-200 hover:bl-text-white">
                  <a href="/zh-CN/faucet">{{$t('m.slt')}}</a>
                </div>
              </li>
              <li>
                <div
                  class="bl-font-body bl-text-lg md:bl-text-[15px] bl-text-primary-foreground bl-duration-200 hover:bl-text-white">
                  <a href="#" target="_blank" rel="noreferrer">{{$t('m.qb')}}</a>
                </div>
              </li>
              <li>
                <div
                  class="bl-font-body bl-text-lg md:bl-text-[15px] bl-text-primary-foreground bl-duration-200 hover:bl-text-white">
                  <a href="#" target="_blank" rel="noreferrer">{{$t('m.llq')}}</a>
                </div>
              </li>
              <li>
                <div
                  class="bl-font-body bl-text-lg md:bl-text-[15px] bl-text-primary-foreground bl-duration-200 hover:bl-text-white">
                  <a href="#" target="_blank" rel="noreferrer">{{$t('m.cswllq')}}</a>
                </div>
              </li>
            </ul>
          </div>
          <div class="bl-flex bl-flex-col bl-gap-2">
            <div class="bl-text-black bl-text-base/normal md:bl-text-xl/normal">{{$t('m.about')}}</div>
            <ul class="bl-flex bl-flex-col bl-gap-1">
              <li>
                <div
                  class="bl-font-body bl-text-lg md:bl-text-[15px] bl-text-primary-foreground bl-duration-200 hover:bl-text-white">
                  <a href="#fa">{{$t('m.fa')}}</a>
                </div>
              </li>
              <li>
                <div
                  class="bl-font-body bl-text-lg md:bl-text-[15px] bl-text-primary-foreground bl-duration-200 hover:bl-text-white">
                  <a href="#tu">{{$t('m.tu')}}</a>
                </div>
              </li>
              <li>
                <div
                  class="bl-font-body bl-text-lg md:bl-text-[15px] bl-text-primary-foreground bl-duration-200 hover:bl-text-white">
                  <a href="#news">{{$t('m.news')}}</a>
                </div>
              </li>
              <li>
                <div
                  class="bl-font-body bl-text-lg md:bl-text-[15px] bl-text-primary-foreground bl-duration-200 hover:bl-text-white">
                  <a href="#gzjh">{{$t('m.gzjh')}}</a>
                </div>
              </li>
              <li>
                <div
                  class="bl-font-body bl-text-lg md:bl-text-[15px] bl-text-primary-foreground bl-duration-200 hover:bl-text-white">
                  <a href="#sheq">{{ $t('m.sheq') }}</a>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="bl-w-full bl-p-16 bl-flex bl-flex-col bl-items-center bl-justify-center bl-gap-5">
          <img src="@/assets/bit.png" class="bl-w-[155px] bl-h-[155px] logo3" />
          <!-- <img src="https://www.bitlayer.org/images/bitlayer.png"
            alt="Bitlayer" class="bl-w-[176px] bl-h-[44px]"> -->
        </div>
      </div>
      <div class="bl-flex bl-justify-between bl-py-6 bl-px-11">
        <div class="bl-text-primary bl-text-base/normal md:bl-text-xl/normal">(C) 2024 BitFAST</div>
        <div class="bl-hidden bl-gap-10">
          <div class="bl-text-primary bl-text-base/normal md:bl-text-xl/normal">Terms &amp; Condition</div>
          <div class="bl-text-primary bl-text-base/normal md:bl-text-xl/normal">Privacy Policy</div>
        </div>
      </div>
    </footer>

  </div>


</template>

<script>
// @ is an alias to /src
export default {

  data() {
    return {
      pdfSrc: '/BITFAST.pdf'
    }
  },
  name: 'HomeView',
  methods: {
    pdf() {
     
        this.$router.push('/book')
      
    },
    handleScroll() {
      // 处理滚动事件
      var time2 = document.getElementById('time2');
      var time3 = document.getElementById('time3');
      var time4 = document.getElementById('time4');
      var time5 = document.getElementById('time5');
      var time6 = document.getElementById('time6');
      var time7 = document.getElementById('time7');
      var c1 = document.getElementById('js-card1');
      var c2 = document.getElementById('js-card2');
      var c3 = document.getElementById('js-card3');
      var p = window.scrollY
      console.log(p)
      if (p > 1417 && p < 1524) {

        time2.classList.add('hover');
        time3.classList.remove('hover');
        time4.classList.remove('hover');
        time5.classList.remove('hover');
        time6.classList.remove('hover');
        time7.classList.remove('hover');
      }
      if (p > 1524 && p < 1640) {

        time3.classList.add('hover');
        time2.classList.remove('hover');
        time4.classList.remove('hover');
        time5.classList.remove('hover');
        time6.classList.remove('hover');
        time7.classList.remove('hover');
      }
      if (p > 1640 && p < 1750) {
        console.log("点击" + window.scrollY)
        time4.classList.add('hover');
        time2.classList.remove('hover');
        time3.classList.remove('hover');
        time5.classList.remove('hover');
        time6.classList.remove('hover');
        time7.classList.remove('hover');
      }
      if (p > 1750 && p < 1882) {

        time5.classList.add('hover');
        time2.classList.remove('hover');
        time3.classList.remove('hover');
        time4.classList.remove('hover');
        time6.classList.remove('hover');
        time7.classList.remove('hover');
      }
      if (p > 1882 && p < 1997) {

        time6.classList.add('hover');
        time2.classList.remove('hover');
        time3.classList.remove('hover');
        time4.classList.remove('hover');
        time5.classList.remove('hover');
        time7.classList.remove('hover');
      }
      if (p > 1997 && p < 2188) {

        time7.classList.add('hover');
        time2.classList.remove('hover');
        time3.classList.remove('hover');
        time4.classList.remove('hover');
        time5.classList.remove('hover');
        time6.classList.remove('hover');
      }
      if (p > 2412 && p < 2681) {
        c1.classList.add('card-hover');
        c2.classList.remove('card-hover');
        c3.classList.remove('card-hover');
      }
      if (p > 2681 && p < 2848) {
        c2.classList.add('card-hover');
        c1.classList.remove('card-hover');
        c3.classList.remove('card-hover');
      }
      if (p > 2848 && p < 3100) {
        c3.classList.add('card-hover');
        c1.classList.remove('card-hover');
        c2.classList.remove('card-hover');
      }
      if (p > 3100) {
        c3.classList.remove('card-hover');
        c1.classList.remove('card-hover');
        c2.classList.remove('card-hover');
      }

    },

  },
  mounted() {
    // 添加事件监听
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // 检查是否为H5页面
    if ("standalone" in navigator && navigator.standalone) {
      return true; // 是H5添加到主屏幕的页面
    }
    // 检查是否为移动设备
    const regex = /android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i;
    if (regex.test(userAgent)) {
      window.addEventListener('scroll', this.handleScroll);
    }

  },
  beforeDestroy() {
    // 移除事件监听
    window.removeEventListener('scroll', this.handleScroll);
  }

}
</script>
<style>
@import url('@/assets/index-BHedg5qa.css');

.draw-nav.el-collapse-item__header {
  background-color: #e46f1b !important;
}

.home {
  padding-top: 60px;
  padding-bottom: 100px;
}

.about {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-size: 1.25rem;

}

.home1 {
  width: 45%;
  margin-top: 40px;
}

.home1-1 {
  width: 90%;
  display: flex;
  justify-content: space-between;
}

.home1-1 span {
  width: 16%;
  height: 5px;
  background-color: #e46f1b;
}

.home1-2 {
  width: 90%;
  position: relative;
  overflow: hidden;
  height: 50px;
  border: #e46f1b 1px solid;
  border-top-width: 0;
  background: linear-gradient(0deg, rgba(227, 110, 27, 0.40) 0.93%, rgba(0, 0, 0, 0.40) 100%), #000;

}

.home1-2:hover {
  box-shadow: 0 9px 10px #e46f1b;
}

.home1-2-1 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;

}

.home1-txt {
  font-size: 1rem;
  line-height: 1.5rem;
  color: #e46f1b;
}

.home1-txt2 {
  color: aliceblue;
  font-size: 1.5rem;
  padding: 0 .25rem;
  font-weight: 600;
}

.home1-img {
  position: absolute;
  width: 6rem;
  right: 10px;
  top: 15px;
}

h3 {
  margin: 0;
}

.white {
  color: #aeb5c6;
}

.yellow {
  color: #e46f1b;
}

.home1-3 {
  margin-top: 10px;
  font-size: 6vw;
  text-align: center;
  line-height: 110px;

}

.pdf1{
  display: block;
}
.pdf2{
  display: none;
}
@media (max-width: 768px) {
  .home1-3 {
    font-size: 45px;
    line-height: 1;
  }
  .pdf1{
  display: none;
}
.pdf2{
  display: block;
}

}

.qj2 {
  clip-path: polygon(8px 0,
      100% 0,
      100% 8px,
      100% calc(100% - 8px),
      calc(100% - 8px) 100%,
      8px 100%,
      0 100%,
      0 8px)
}

.qj {
  clip-path: polygon(18px 0,
      100% 0,
      100% 13px,
      100% calc(100% - 13px),
      calc(100% - 13px) 100%,
      13px 100%,
      0 100%,
      0 13px)
}

.home1-4 {
  margin-top: 20px;
  text-align: center;
  font-size: 1.125rem;
  font-weight: 700;
  height: 2.5rem;
  line-height: 2.5rem;
  width: 35%;
  padding: 0 .75rem;
  background-color: #fff;
  border-radius: 5px;
  color: #000;
  align-items: center;

}

.bg-w {
  background-color: #aeb5c6;
}

.btn {
  background: linear-gradient(to bottom, #e46f1b 0%, #dc9f74 100%);
  color: #fff;
}

.home1-4:hover {

  background-color: #e46f1b;


}

.home1-3-txt {
  font-size: 1.25rem;
  margin-top: 20px;
  font-weight: 330;
  text-align: center;
}

.home1-5 {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
}

.w {
  width: 30%;
  height: 3.5rem;
  line-height: 3.5rem;
  font-size: 1.25rem;
}



.zoom-animation {

  animation: zoom 2s infinite alternate;
}

@keyframes zoom {
  0% {
    transform: scale(0.8);
  }

  100% {
    transform: scale(1.1);
  }
}

@media (max-width: 768px) {
  .home1-3-txt {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .home1-4 {
    width: 35%;
    font-size: 0.85rem;

  }
}

/* .ww{
  white-space: nowrap;
} */

.home2 {
  width: 90%;
  margin-top: 50px;
}

.home2-1 {
  display: flex;
  flex-direction: row;
}

.home2-1-txt {
  color: #e46f1b;


}

.s1 {
  font-size: 35px;
  font-weight: bold;
}

.home2-2-txt {
  color: #fff;
  margin-left: 10px;

}

.num-grid {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.num {
  width: 100%;
}

.right {
  text-align: right;
  margin-top: 8px;
}

.fa {
  height: 55px;
  background-color: #e46f1b33;
  line-height: 55px;
  padding: 0 90px;
  position: relative;
  margin-top: 80px;
  font-size: 26px;
}

.jb {
  position: absolute;
  width: 15px;
  height: 15px;
  border-top: #e46f1b 1px solid;
  border-left: #e46f1b 1px solid;
  top: 0;
  left: 0;
}

.jb2 {
  position: absolute;
  width: 15px;
  height: 15px;
  border-bottom: #e46f1b 1px solid;
  border-right: #e46f1b 1px solid;
  bottom: 0;
  right: 0;
}

.w2 {
  max-width: 850px;
  font-size: 90px;
  text-align: center;
  margin-top: 20px;
  line-height: 1.1;
  font-weight: bold;
}

.w3 {
  margin-top: 30px;
}


.card {
  width: 90%;
  margin: 0 auto;

}

.mobile {
  display: none;
}

.el-carousel-item {
  background-color: #aeb5c6;
}

.card-item {
  display: flex;

  justify-content: space-between;

  width: 100%;
  height: 100%;

}

.mobile-card-item {
  display: flex;
  width: 100%;
  height: 100%;

}

.el-carousel {
  margin-top: 70px;
}

.card-r {
  color: #000;
  font-weight: bold;
  width: 45%;
  height: 100%;
  margin: auto;
  text-align: center;
  padding-top: 30px;
  background-color: #e46f1b;
}

.mobile .card-r {
  color: #000;
  font-weight: bold;
  width: 80%;
  height: 250px;
  margin: auto;
  text-align: center;
  padding-top: 10px;
  background-color: #e46f1b;
  margin-top: 20px;
}

.card-img {
  width: 350px;
  height: auto;
  margin: auto;
}

.mobile .card-img {
  width: 300px;
  height: 250px;
  margin: auto;
}

.card-t-c {
  margin-top: 100px;
}

.card-t1 {
  font-size: 30px;
}

.card-t2 {
  line-height: 60px;
  font-size: 60px;
  margin-bottom: 20px;
}

.card-t3 {
  font-size: 20px;
}

.card .el-carousel__button {
  width: 39px !important;
  height: 6px;
}


/* 时间轴 */
.lxt {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 90px;
}

.lxt-title {
  font-size: 45px;
  margin-top: 90px;
}

.hover .group-hover\:bl-text-white {
  --tw-text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.hover .group-hover\:bl-bg-primary {
  background-color: #e46f1b;
  background-color: hsl(var(--primary));
}

.hover .group-hover\:bl-w-24 {
  width: 6rem;
}

.hover .group-hover\:bl-opacity-100 {
  opacity: 1;
}


.hover .group-hover\:bl-translate-x-10 {
    --tw-translate-x: 2.5rem;
    transform: translate(2.5rem, var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.hover .bl-text-sm\/normal {
    font-size: .815rem;
    line-height: 1.1rem;
}
/* 时间轴 end */

/* 卡片 */
@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

.newcard {
  display: flex;
  justify-content: center;
  border: solid 1px #303131;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;

}

.card-container {
  display: flex;
  margin: 60px;
}

.newcard1 {
  font-weight: bold;
  font-size: 45px;
  text-align: center;
  margin-top: 50px;
}

.ccard {
  flex: 1;
  width: 260px;
  height: 400px;
  margin: 0 15px;
  text-align: center;
  transition: flex 0.3s;
  border: solid 1px #e46f1b;
  position: relative;
  overflow: hidden;

  /* 平滑过渡效果 */
}

/* 当鼠标悬停在第一张卡上时 */
.ccard:first-child:hover {
  flex: 4;
  /* 变宽 */
}

.ccard:first-child:hover .txt1-opacity {
  opacity: 1;
  /* 变宽 */
}

.ccard:last-child:hover .txt3-opacity {
  opacity: 1;
}

.ff:hover .txt2-opacity {
  opacity: 1;
}

.ccard:last-child:hover {
  flex: 4;
  /* 变宽 */
}

.ff:hover {
  flex: 4;
  /* 变宽 */
}

.ff .ccard-text:hover {
  flex: 4;
  /* 变宽 */
}

.card-hover .ccard-txt {
  opacity: 1;
}

.card-hover {
  flex: 4 !important;
}


.newcard {
  /* padding: 15vmin 10vmin; */
  /* background-color: #fff; */
  /* border-radius: 5vmin; */
  background-repeat: 1vmin;

  position: relative;
  overflow: hidden;
  z-index: 0;
  /* 为内部子元素开启层级开关 */
}

.newcard::before {
  content: '';
  width: 200%;
  height: 200%;
  background-color: #000;
  position: absolute;
  left: -50%;
  top: -50%;
  background-image: conic-gradient(transparent, #e46f1b, transparent 30%);
  z-index: -2;
  animation: rotate 3s linear infinite;
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

.newcard::after {
  content: '';
  position: absolute;
  inset: 0.5vmin;
  background-color: #000;
  /* border-radius: 1vmin; */
  z-index: -1;
}

.ccard-c {
  background-size: contain;
  /* 背景图片覆盖整个元素 */
  background-repeat: no-repeat;
  /* 背景图片不重复 */
  /* opacity: .4; */
  background-position: left bottom;
  height: 100%;
  margin: 1rem;
  text-align: left;

}

.ccard-1 {
  background-image: url('@/assets/car-01.png');
  width: 500px;
  background-position: 0 80px;


}


.ccard-2 {
  background-image: url('@/assets/car-02.png');
  width: 850px;
  background-position: 0 95px;
}

.ccard-3 {
  background-image: url('@/assets/car-03.png');
  width: 850px;
  background-position: 0 -40px;
}

.ccard-txt {
  margin-left: 10px;

}

.txt1-opacity {
  opacity: 0;
}

.txt2-opacity {
  opacity: 0;
}

.txt3-opacity {
  opacity: 0;
}

.ccard-txt1 {
  font-size: 44px;
  color: #e46f1b;
  font-weight: bold;


}

.ccard-txt2 {
  font-size: 36px;
  color: #e46f1b;

}

.ccard-txt3 {
  font-size: 1.25rem;
  color: #fff;
  margin-top: 20px;
  width: 470px;

}

.news-title {
  font-size: 80px;
}

.news-title1 {
  font-size: 30px;
}

/* 卡片 emd*/
/* 新闻卡片 */
.card-news {
  margin-top: 50px;
}

.card-news-xh {
  position: absolute;
  left: 30px;
  bottom: 20px;
  font-size: 30px;
  font-weight: bold;
}

.card-news-xh2 {
  position: absolute;
  left: 30px;
  bottom: 20px;
  font-size: 30px;
  font-weight: bold;
}

.card-news .el-carousel__item:nth-child(2n+1) {
  background-color: #000;
}

/* .item2 {
  position: relative;
  background-size:contain; 
  background-repeat: no-repeat; 
  background-position: center; 
  width: 100%;
  height: 100%;
} */

/* .item21{
  
  background-image: url('@/assets/event-4.png');
  
  }
  .item22{
  
  background-image: url('@/assets/event-4.png');
  
  }
  .item23{
  
  background-image: url('@/assets/event-4.png');
  
  }
  .item24{
  
  background-image: url('@/assets/event-4.png');
  
  } */
.card-news .el-carousel {
  width: 90%;
  margin: 30px;
}

.card-news .el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  margin: 0;
}

.card-news .el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.card-news .el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}

.webnews {
  display: block;
}

.phonenews {
  display: none;
}

/* 新闻卡片 end*/
@media (max-width: 768px) {

  .phonenews {
    display: block;
  }

  .webnews {
    display: none;
  }
  .el-carousel__container {
    height: 200px;
}
  .phonenews .el-carousel__indicators--outside button {
    background-color: #e46f1b;
    opacity: .24;
  }

  .home1 {
    width: 90%;
  }

  .w2 {
    font-size: 45px;
    max-width: 380px;
  }

  .w3 {
    font-size: 1rem;
  }

  .s1 {
    font-size: 22px;
  }

  .card {
    display: none;
  }

  .mobile {
    display: block;
  }

  .mobile .card-item {
    flex-direction: column;
  }

  .card-t2 {
    font-size: 35px;
    /* margin-bottom: 10px; */
    line-height: 30px;
    
  }

  .card-t-c {
    margin-top: 30px;
  }

  .card-t3 {
    font-size: 15px;
  }

  .mobile .el-carousel__indicator--vertical .el-carousel__button {
    width: 3px !important;
    height: 36px !important;
    margin: 10px 0 !important;
    background-color: #e46f1b !important;
  }

  .bl-px-6 {
    padding-right: 9rem;
  }

  #time1 .group-hover\:bl-text-white {
    --tw-text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  #time1 .group-hover\:bl-bg-primary {
    background-color: #e46f1b;
    background-color: hsl(var(--primary));
  }

  #time1 .group-hover\:bl-w-24 {
    width: 6rem;
  }

  #time1 .group-hover\:bl-opacity-100 {
    opacity: 1;
  }

  #time1 .group-hover\:bl-translate-x-10 {
    --tw-translate-x: 2.5rem;
    transform: translate(2.5rem, var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .card-container {
    flex-direction: column;
    height: 500px;
    margin: 30px;
  }

  .ccard {
    flex: 1;
    width: 330px;
    height: 400px;
    margin: 8px 0;
    text-align: center;

  }

  .newcard1 {
    font-size: 30px;
    margin-top: 30px;
  }

  .ccard-txt1 {
    font-size: 30px;

  }

  .ccard-txt2 {
    font-size: 23px;

  }

  .ccard-txt3 {
    font-size: 1rem;
    width: 285px;

  }

  .ccard-1 {

    background-position: 0 10px;


  }


  .ccard-2 {

    background-position: 0 10px;
  }

  .ccard-3 {

    background-position: 0 10px;
  }

  .news-title {
    font-size: 45px;
  }

  .news-title1 {
    font-size: 20px;
  }



  .card-news-xh {

    left: 10px;
    bottom: 10px;
    font-size: 20px;
    font-weight: bold;
  }

  .bl-h-\[155px\] {
    height: 65px;
  }


.home1-txt {
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: #e46f1b;
}

.home1-txt2 {
  color: aliceblue;
  font-size: 1rem;
  padding: 0 .25rem;
  font-weight: 600;
}
.lxt-title {
    font-size: 30px;
    margin-top: 60px;
}


}
</style>