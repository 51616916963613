<template>
  <header>
    <nav class="navbar" id="nav">
      <div class="navbar-logo" @click="goHome">
        <img class="logo1" src="@/assets/bit.png" />
        <img class="logo2" src="@/assets/logo.png" />
      </div>
      <div class="right-nav">
        <div class="navbar-menu">
          
          <el-dropdown placement="bottom-start">
            <span class="el-dropdown-link">
              {{$t('m.develop')}}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <div class="xl-item">
                <div class="xl-item-1">
                  <span class="xl-item-xh">01</span>
                  <div class="xl-item-hover" v-if="$t.local=='zh'">
                    <div>
                      <div>{{$t('m.develop')}}</div>
                      <span class="xl-item-2">{{$t('m.develop_1')}}</span>
                    </div>
                  </div>
                  <div class="xl-item-hover xl-item-hover-en " v-else>
                    <div>
                      <div>{{$t('m.develop')}}</div>
                      <span class="xl-item-2">{{$t('m.develop_1')}}</span>
                    </div>
                  </div>
                </div>

              </div>
              <div class="line"></div>

              <div class="xl-item">
                <div class="xl-item-1">
                  <span class="xl-item-xh">02</span>
                  <div class="xl-item-hover" v-if="$t.local=='zh'">
                    <div>
                      <div>{{$t('m.word')}}</div>
                      <div class="xl-item-2">{{$t('m.word_1')}}</div>
                    </div>
                  </div>
                  <div class="xl-item-hover xl-item-hover-en " v-else>
                    <div>
                      <div>{{$t('m.word')}}</div>
                      <div class="xl-item-2">{{$t('m.word_1')}}</div>
                    </div>
                  </div>
                </div>

              </div>

            </el-dropdown-menu>
          </el-dropdown>
          <div class="menu-item">{{$t('m.st_center')}}</div>
          <div class="menu-item">{{$t('m.user_center')}}</div>
          <el-dropdown placement="bottom-start">
            <span class="el-dropdown-link">
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none"
                class="bl-size-5">
                <path
                  d="M11.02 0c-6.065 0-11 4.934-11 11s4.935 11 11 11c6.066 0 11-4.934 11-11s-4.935-11-11-11ZM1.348 11.647h3.8c.058 1.325.274 2.628.665 3.882H2.443a9.61 9.61 0 0 1-1.096-3.882Zm3.8-1.294h-3.8A9.61 9.61 0 0 1 2.443 6.47h3.365a15.22 15.22 0 0 0-.66 3.882ZM7.179 6.47h7.63a13.95 13.95 0 0 1 .737 3.882H6.442c.06-1.33.31-2.634.736-3.882Zm-.736 5.176h9.104a13.999 13.999 0 0 1-.74 3.882H7.182a13.954 13.954 0 0 1-.74-3.882Zm10.398 0h3.854a9.608 9.608 0 0 1-1.096 3.882h-3.422c.391-1.254.607-2.557.664-3.882Zm0-1.294a15.223 15.223 0 0 0-.66-3.882h3.418a9.65 9.65 0 0 1 1.096 3.882H16.84Zm1.927-5.177h-3.046a15.777 15.777 0 0 0-2.006-3.494 9.73 9.73 0 0 1 5.052 3.494ZM11.743 1.33a14.655 14.655 0 0 1 2.552 3.846H7.692a14.624 14.624 0 0 1 2.55-3.843c.257-.02.514-.039.778-.039.244 0 .483.02.723.036ZM8.26 1.7a15.741 15.741 0 0 0-1.993 3.476H3.273A9.729 9.729 0 0 1 8.26 1.7ZM3.273 16.822H6.27a15.716 15.716 0 0 0 2.002 3.48 9.718 9.718 0 0 1-5-3.48Zm6.984 3.844a14.696 14.696 0 0 1-2.56-3.844h6.592a14.659 14.659 0 0 1-2.56 3.847c-.235.016-.47.036-.708.036a9.89 9.89 0 0 1-.764-.039Zm3.444-.346a15.755 15.755 0 0 0 2.015-3.498h3.051a9.729 9.729 0 0 1-5.066 3.498Z"
                  fill="currentColor"></path>
              </svg>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item style="background-color: #e46f1b;">
                <div class="xl-item" @click="selectLan('en')">
                  <div class="xl-item-1">
                    <span class="xl-item-xh">01</span>
                    <div class="xl-item-hover">
                      <div>
                        <div>English</div>
                        <!-- <div class="xl-item-2">开始在chian构建</div> -->
                      </div>
                    </div>
                  </div>

                </div>
              </el-dropdown-item>


              <div class="line"></div>
              <el-dropdown-item style="background-color: #e46f1b;">
                <div class="xl-item" @click="selectLan('zh')">
                  <div class="xl-item-1">
                    <span class="xl-item-xh">02</span>
                    <div class="xl-item-hover">
                      <div>
                        <div>简体中文</div>
                        <!-- <div class="xl-item-2">面向开发者的文档</div> -->
                      </div>
                    </div>
                  </div>

                </div>
              </el-dropdown-item>



            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <div style="display: flex;align-items: center;">
          <!-- <div class="menu-item gradient-border" id="box">连接钱包</div> -->
          <h1 class="box">{{ $t('m.connect') }}</h1>

          <!-- <div class="menu-item gradient-border" id="box">连接钱包</div> -->


          <div class="navbar-toggle" @click="toggleMenu">
            <span style="width: 15px;"></span>
            <span></span>
            <span></span>
          </div>
        </div>

      </div>

    </nav>

    <el-drawer :withHeader="false" :visible.sync="drawer" direction="rtl" size="80%" :before-close="handleClose">
      <div class="draw-nav">

        <div class="draw-p">
          <div class="draw-item" @click="list(1)">
            <div>{{$t('m.develop')}}</div>
            <i class="el-icon-arrow-right"></i>
          </div>
          <div :class="show == 1 ? 'active' : 'noactive'">
            <div class="draw-item-2">
              <span class="draw-item-xh">01</span>
              <div>
                <div>{{$t('m.develop')}}</div>
                <div class="draw-item-txt">{{$t('m.develop_1')}}</div>
              </div>
            </div>

            <div class="draw-item-2">
              <span class="draw-item-xh">02</span>
              <div>
                <div>{{$t('m.word')}}</div>
                <div class="draw-item-txt">{{$t('m.word_1')}}</div>
              </div>
            </div>

          </div>

        </div>

        <div class="draw-p">
          <div class="draw-item">
            <div>{{$t('m.user_center')}}</div>
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>

        <div class="draw-p">
          <div class="draw-item" @click="list(2)">
            <div>{{$t('m.lang')}}</div>
            <i class="el-icon-arrow-right"></i>
          </div>
          <div :class="show == 2 ? 'active' : 'noactive'">
            <div class="draw-item-2" @click="selectPhoneLan('en')">
              <span class="draw-item-xh">01</span>
              <div>
                <div>English</div>

              </div>
            </div>

            <div class="draw-item-2" @click="selectPhoneLan('zh')">
              <span class="draw-item-xh">02</span>
              <div>
                <div>简体中文</div>
              </div>
            </div>

          </div>

        </div>

      </div>
    </el-drawer>
  </header>
</template>

<script>
let y = 0;
export default {
  name: 'NavBar',
  data() {
    return {
      drawer: false,
      show: 0,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);

  },
  beforeDestroy() {
    // 移除事件监听
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    goHome(){
      this.$router.go('/')
    },
    selectLan(lan) {
      var local = localStorage.getItem('localeLanguage')
      if (local != lan) {
        localStorage.setItem('localeLanguage', lan);
        this.$i18n.locale = lan
      }
    },
    selectPhoneLan(lan) {
      var local = localStorage.getItem('localeLanguage')
      this.show = 0
      if (local != lan) {
        localStorage.setItem('localeLanguage', lan);
        this.$i18n.locale = lan
      }
      this.drawer=false
    },
    list(e) {
      if (e == this.show) {
        this.show = 0
      } else {

        this.show = e
      }
    },
    handleScroll() {
      var nav = document.getElementById('nav');
      var p = window.scrollY;
      console.log("y:" + y)
      if (p > y + 200) {
        nav.classList.add('hidden');
        y = p

      }
      if (p < y - 200) {
        nav.classList.remove('hidden');

        y = p
      }


    },
    handleClose(done) {
      done();
    },
    toggleMenu() {
      this.drawer = true
    },
  }
};
</script>

<style scoped>
.draw-nav {
  background-color: #e46f1b;
  width: 100%;
  height: 100%;
}


.right-nav {
  display: flex;
  align-items: center;
}

.menu-item {
  padding-left: 3rem;
  width: max-content;
}

.menu-item:hover {
  color: #f5f5f5;
  cursor: pointer;
}

@media (min-scroll-y: 500px) {
  .navbar {
    visibility: visible;
    opacity: 1;
  }
}

.navbar {
  position: fixed;
  top: 0;
  transition: transform 0.5s;
  background-color: rgba(255, 255, 255, 0.5);
  width: 100%;
  z-index: 100;
  opacity: 0.8;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px;
  background-color: #000;
  font-size: 1.25rem;
  font-weight: 500;
  color: #e46f1b;
  font-family: var(--bt-locale-font-sans), ui-sans-serif, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

}

.navbar-logo a {

  font-size: 24px;
  text-decoration: none;
}

.navbar-toggle {
  display: none;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 10px;
  align-items: center;
  margin-left: 20px;
}

.navbar-toggle span {
  display: block;
  height: 2px;
  width: 25px;
  background-color: #e46f1b;
  margin: 4.5px 0;
}

.el-dropdown:hover {
  color: #f5f5f5;
  cursor: pointer;
}

.el-dropdown-menu {
  border-radius: 0;
}

.el-dropdown {
  font-size: 1.35rem;
  font-weight: 500;
  color: #e46f1b;
}

.el-dropdown-menu {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  padding: 10px 0;
  margin: 5px 0;
  background-color: #e46f1b;
  border: 1px solid #e46f1b;
  border-radius: 0;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
}

.navbar-menu {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  align-items: center;

}

.el-dropdown-link {
  display: flex;
  align-items: center;
  margin-left: 3rem;
}

.navbar-menu li {
  margin-right: 20px;
}

.navbar-menu li a {
  color: #e46f1b;
  text-decoration: none;
}

/* 消除小三角 */
.el-popper {
  overflow: auto;
  background-color: #e46f1b;
  z-index: 1;
}

.xl-item {
  display: flex;
  font-size: 1.35rem;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 2rem;
  color: #000;

}

.xl-item-xh {
  font-size: 1.23rem;

}

.xl-item-1 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.xl-item-2 {
  font-size: 1rem;
  color: #565658;
}

.line {
  height: 0.01rem;
  background-color: #333332;
  margin: 0 0.5rem;
}

.xl-item-hover {
  padding: 0.3rem 2rem;
  margin-left: 2rem;
  width: 12rem;
}
.xl-item-hover-en {
  width: 20rem;
}

.xl-item-hover:hover {
  background-color: #000;
  color: #f5f5f5;

  border-top-left-radius: 1rem;
  border-bottom-right-radius: 1rem;

}

.gradient-border:hover {
  background-color: #e46f1b;
}

@keyframes ants {
  100% {
    background-position: 100%;
  }
}

#box {
  /* width:200px; */
  /* height: 70px; */
  display: flex;
  justify-content: center;
  padding: 0.5rem 1.3rem;
  border: 1.5px solid transparent;

}



.logo3{
  width: 500px;
  height: 100px;
}
.logo1 {
  display: block;
  width: 130px;
  height: 30px;
}

.logo2 {
  display: none;
}

/* Responsive styles */
@media (max-width: 768px) {
  .navbar-menu {
    /* position: absolute; */
    top: 100%;
    left: 0;
    right: 0;
    background-color: #f5f5f5;
    flex-direction: column;
    transform: translateY(-100%);
    transition: transform 0.3s ease-in-out;
    display: none;
    margin: 0 auto;
  }

  .navbar-menu.is-visible {
    transform: translateY(0);
  }

  .navbar-menu li {
    margin-right: 0;
    padding: 10px 20px;
  }

  .navbar-toggle {
    display: block;
  }

  .navbar {
    display: flex;
    justify-content: space-between;
    font-size: 1rem;
  }

  .logo2 {
    display: block;
    width: 35px;
    height: 35px;
  }

  .logo1 {
    display: none;
  }

  .box {
    font-size: 1rem !important;
  }


}

/* 
连接 */
.box {
  background: linear-gradient(#e46f1b, #e46f1b) left -3px top 0, linear-gradient(#e46f1b, #e46f1b) left -3px top -3px, linear-gradient(#e46f1b, #e46f1b) right -3px top 0, linear-gradient(#e46f1b, #e46f1b) right -3px top -3px, linear-gradient(#e46f1b, #e46f1b) left -3px bottom 0, linear-gradient(#e46f1b, #e46f1b) left -3px bottom -3px, linear-gradient(#e46f1b, #e46f1b) right -3px bottom 0, linear-gradient(#e46f1b, #e46f1b) right -3px bottom -3px;
  background-color: #11121280;
  background-repeat: no-repeat;
  background-size: 20px 20px, 20px 20px;
  background-size: 3px 6px, 10px 3px;
  border: 1px solid transparent;
  backdrop-filter: blur(1px);
  font-size: 1.35rem;
  text-align: center;
  padding: 1px 10px;
  margin-left: 50px;
}

.box:hover {
  color: #fff;
  /* background-color: #e46f1b;
  border-radius: 10px; */

}

.hidden {
  display: none;
  transform: scale(0);
}

.show {
  display: block;
}

.draw-p {
  padding: 25px;
  font-size: 1.125rem;
  color: #000;
  font-weight: bold;
}

.draw-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: rgb(174, 86, 24) solid 0.1px;
  padding-bottom: 10px;
}

.draw-item-2 {
  display: flex;
  align-items: center;
  margin-left: 15px;
  padding: 20px 0;
  line-height: 20px;
  border-bottom: rgb(174, 86, 24) solid 0.1px;
}

.draw-item-txt {
  font-size: .9rem;
  color: #505252;
}

.draw-item-xh {
  margin-right: 30px;
}

.noactive {
  display: none;
}

.active {
  display: block;
}

/* /// */
</style>