export default {
  m: {   
      connect:'连接钱包',
      develop:'开发者',
      st_center:"生态应用中心",
      user_center:"用户中心",
      develop_1:"开始在chian构建",
      word:"文档",
      word_1:"面向开发人员的支持文档",
      lang:"语言",
      jl:"关注我们即将到来的",
      kt:"空投",
      zg:"资格",
      ts:"探索",
      knx:"比特币的可能性",
      bps:"白皮书",
      ll:"比特生态的中坚力量、安全高速的Layer 2 方案",
      gj:"开始构建",
      sy:"赚取收益",
      gas:"平均gas费",
      fa:"解决方案",
      rh:"比特币二层方案形态如何",
      hxfa:"比特币生态系统的核心方案",
      yz:"比特币一层验证",
      aqx:"通过bitvm继承比特币安全性",
      lg:"乐观Rollups",
      md:"双向锚定通过DLC和BitVM，带来创新的多重签名模型",
      tl:"完整图灵系统",
      vm:"支持VMs,实现EVM环境完美兼容",
      tu:"路线图",
      lc:"比特币的旅程",
      year:"年",
      rsb:"染色币",
      tmgl:"染色币标记区块链代币以代表真实世界资产，确保安全和透明管理。",
      szzc:"为比特币添加自定义代币和智能合约，用于构建和交易数字资产。",
      jzsj:"隔离见证升级",
      kkzx:"隔离见证协议升级通过将签名数据与交易数据分离，增加了区块容量并改善了可扩展性。",
      jh:"基于区块链的移动游戏《Spells of Genesis》在奇幻背景中将交易卡与街机元素相结合。",
      ys:"具有青蛙特征的 Rare Pepes 数字交易卡在比特币区块链上创建和交换，象征独特的可收藏加密艺术。",
      tsj:"Taproot 升级",
      hygn:"比特币协议升级通过新的 Schnorr 签名方案，增强了隐私、效率和智能合约功能。",
      xygn:"Ordinals 协议在比特币区块链的每个聪上铭刻独特的数据，赋予 NFT 类似的功能。",
      blc:"BitFAST的旅程",
      test:"测试",
      qd:"内部网络启动，进行基础功能测试和性能调优",
      gw:"公测",
      fbgt:"发布公开测试网集成VMs，邀请社区参与测试并完成空投",
      zw:"主网",
      wl:"发布正式版本网络，开展市场推广活动，吸引更多用户参与",
      news:"活动与新闻",
      news1:"BitFAST生态活动与新闻",
      zxjz:"关注我们最新进展",
      gzjh:"工作机会",
      zwkq:"职位空缺",
      history:"让我们一起创造比特币历史！",
      fzr:"开发者关系负责人",
      kfzgx:"负责拓展和维护公链生态的开发者关系",
      kfzjy:"处理与全球开发者技术社区、生态中的技术交流和开发者教育",
      more:"更多信息",
      yjy:"协议研究员",
      tdian:"基于行业痛点进行区块链前沿研究",
      lw:"撰写高质量的研究报告和论文",
      tskq:"探索职位空缺",
      sheq:"社区",
      jrsq:"加入社区",
      hdsq:"与 BitFAST 互动",
      jrdw:"加入 BitFAST 开发者队伍",
      wmgj:"与我们一同构建",
      jrdskd:"加入 Discord",
      cyjl:"参与讨论与交流",
      gztt:"关注官方推特",
      zxzxjz:"时刻获取最新进展",
      lxwm:"在领英上联系我们",
      ywmlx:"与我们联系",
      jrdbq:"加入我们的电报群",
      bzzx:"获取帮助和资讯",
      gzmdgx:"关注Medium更新",
      gdzn:"获取更多信息和指南",
      cy:"参与",
      thwj:"头号玩家",
      gj2:"构建",
      gj3:"在 BitFAST 上构建",
      slt:"水龙头",
      qb:"支持的钱包",
      llq:"BitFAST(BTR) 浏览器",
      cswllq:"测试网浏览器",
      about:"关于",
      




  }
}